import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { DarkWebData } from '@idiq-ppd/idiq-client/src/IDIQParser/types/DarkWebData'
import { getDarkWebData } from './actions/darkWeb'
import { SessionStore } from '@/constants'

export interface DarkWebState {
  isFetching: boolean
  error: null | string
  darkWebData?: DarkWebData
}

const initialState: DarkWebState = {
  isFetching: false,
  error: null,
  darkWebData: undefined,
}

const darkWeb: Slice = createSlice({
  name: 'darkweb',
  initialState,
  reducers: {
    cacheDarkWebData: (state, action: PayloadAction<{ darkWebData: DarkWebData }>) => {
      state.darkWebData = action.payload
      state.error = null
      state.isFetching = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDarkWebData.pending, (state) => {
        state.isFetching = true
        sessionStorage?.removeItem(SessionStore.DarkWebData)
      })
      .addCase(getDarkWebData.rejected, (state, action) => {
        state.isFetching = false
        state.error = action.payload as string
      })
      .addCase(getDarkWebData.fulfilled, (state, action) => {
        state.isFetching = false
        state.darkWebData = (action.payload as any).data as DarkWebData

        sessionStorage?.setItem(SessionStore.DarkWebData, JSON.stringify(state.darkWebData))
      })
  },
})

export const { cacheDarkWebData } = darkWeb.actions
export default darkWeb.reducer
