import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, ThunkAPI } from '../types'
import useIDIQServices from '../idiqServices'
import { redirect } from '@/state/actions/redirect'
import { AuthStatuses } from '@/constants'
import { apm } from '@elastic/apm-rum'
import { selectEnrollmentState } from '../selectors'

interface Args {
  membershipNumber: string
  offerCode?: string
}

const matchRequest: AsyncThunkPayloadCreator<void, Args, ThunkAPI> = async (
  args: Args,
  { getState, rejectWithValue, dispatch },
) => {
  const idiqServices = useIDIQServices()
  const { membershipNumber, offerCode } = args
  const { account, identity } = selectEnrollmentState(getState() as RootState)

  try {
    apm.setUserContext({ email: account?.email })
    apm.setCustomContext({ userAction: 'idMatchRequest' })

    const proveAuth = `${identity.trustScore}`
    const response = identity.isProveBypass
      ? await idiqServices.match(membershipNumber, offerCode, proveAuth)
      : await idiqServices.match(membershipNumber, offerCode)

    if (response?.status === 200) {
      await idiqServices.updateUserStatus({
        membershipNo: membershipNumber,
        status: AuthStatuses.L3QuestionsAnswered.status,
        cncStatus: AuthStatuses.L3QuestionsAnswered.cncStatus,
      })
      dispatch(redirect({}))
    }
  } catch (e) {
    const error = e as Error
    apm.captureError(error)
    dispatch(redirect({}))

    return rejectWithValue(error?.message)
  }
}

export const match = createAsyncThunk<void, Args, ThunkAPI>('member/submitVerification', matchRequest)
