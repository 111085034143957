import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { Plan, ThunkAPI } from '../types'
import { DEFAULT_EFFORT, DEFAULT_PLANCODE, NetworkMessages, Routes } from '@/constants'
import useIDIQServices from '../idiqServices'
import { loadTrigger } from '@/state/tracking'
import { apm } from '@elastic/apm-rum'
import router from 'next/router'

interface ReturnVal {
  details: Plan
  effortCode: string
  planCode: string
}

const getDefaultPlanRequest: AsyncThunkPayloadCreator<ReturnVal, void, ThunkAPI> = async (
  _,
  { rejectWithValue, dispatch },
) => {
  try {
    const idiqServices = useIDIQServices()
    const response = await idiqServices.getPlan(DEFAULT_PLANCODE, { offerCode: DEFAULT_EFFORT })

    dispatch(
      loadTrigger({
        trigger: response.data.trackingInfo?.trackingTrigger,
        trackUrl: response.data.trackingInfo?.trackingUrls.TrackingUrl,
      }),
    )

    return {
      details: response.data,
      planCode: DEFAULT_PLANCODE,
      effortCode: DEFAULT_EFFORT,
    }
  } catch (error) {
    apm.captureError(new Error(error as string))
    router.push(Routes.TechError, Routes.TechError)

    return rejectWithValue(NetworkMessages.GetPlanError)
  }
}

export const getDefaultPlan = createAsyncThunk<ReturnVal, void, ThunkAPI>('user/getDefaultPlan', getDefaultPlanRequest)
