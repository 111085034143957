import { loadTrigger } from '@/state/tracking'
import { apm } from '@elastic/apm-rum'
import router from 'next/router'
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import useIDIQServices from '../idiqServices'
import { Activation, ThunkAPI, TrackingInfo } from '../types'
import { Routes, MemberStatus } from '@/constants'

interface Args {
  activationKey: string
}

interface ReturnVal {
  details: Activation
  trackingInfo: TrackingInfo
}

const getActivationRequest: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (
  args: Args,
  { dispatch, rejectWithValue },
) => {
  try {
    const { activationKey } = args
    const idiqServices = useIDIQServices()
    const response = await idiqServices.getActivationDetails(activationKey)

    dispatch(
      loadTrigger({
        trigger: response.data.trackingInfo?.trackingTrigger,
        trackUrl: response.data.trackingInfo?.trackingUrls.TrackingUrl,
      }),
    )

    if (response.data.memberStatus === MemberStatus.Active) {
      router.push(Routes.SignIn)
    } else if (response.data.memberStatus === MemberStatus.Cancelled) {
      router.push(Routes.Unauthorized)
    } else if (
      response.data.memberStatus === MemberStatus.ActivationPending ||
      response.data.memberStatus === MemberStatus.VerificationPending
    ) {
      router.push({
        pathname: Routes.Enroll,
        query: {
          offerCode: response.data.effortCode,
          planCode: response.data.planCode,
          groupingCode: response.data.groupingCode,
        },
      })
    } else {
      router.push(Routes.TechError)
    }

    return {
      details: response.data,
    } as ReturnVal
  } catch (error) {
    apm.captureError(new Error(error as string))
    router.push(Routes.TechError, Routes.TechError)

    return rejectWithValue({})
  }
}

export const getActivation = createAsyncThunk<ReturnVal, Args, ThunkAPI>('user/getActivation', getActivationRequest)
