import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkAPI } from '../types'
import useIDIQServices from '../idiqServices'
import { NetworkMessages, AuthStatuses } from '@/constants'
import { updateUserStatus } from './updateUserStatus'
import { redirect } from '@/state/actions/redirect'

interface Args {
  offerCode: string
  planCode: string
  memberId: string
}

const getVerificationQuestionsRequest: AsyncThunkPayloadCreator<void, Args, ThunkAPI> = async (
  args: Args,
  { rejectWithValue, dispatch },
) => {
  const { memberId, planCode, offerCode } = args
  const idiqServices = useIDIQServices()
  try {
    const response = await idiqServices.getVerificationQuestions(memberId, {
      planCode,
      offerCode,
    })

    if (response.message === NetworkMessages.AccountCodeMissing) {
      dispatch(redirect({ authStatus: AuthStatuses.ManualAuthRequired }))
    }

    dispatch(
      updateUserStatus({
        membershipNumber: memberId,
        ...AuthStatuses.L3QuestionsLoaded,
      }),
    )

    return response.data.questions
  } catch ({ message }) {
    dispatch(redirect({}))

    return rejectWithValue(message)
  }
}

export const getVerificationQuestions = createAsyncThunk<void, Args, ThunkAPI>(
  'member/getVerificationQuestions',
  getVerificationQuestionsRequest,
)
