import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { getResidentData } from './actions'
import { NetworkMessages, SessionStore } from '@/constants'
import { ResidentData } from '@/state/types'

export interface ResidentDataState {
  error: null | string
  isFetching: boolean
  details?: ResidentData
}

const initialState: ResidentDataState = {
  error: null,
  isFetching: false,
}

const residentData: Slice = createSlice({
  name: 'residentData',
  initialState,
  reducers: {
    cacheResidentDataFromSession: (state, action: PayloadAction<ResidentDataState>) => {
      state.details = action.payload.details
      state.error = null
      state.isFetching = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResidentData.pending, (state) => {
        state.error = null
        state.isFetching = true
      })
      .addCase(getResidentData.rejected, (state, action) => {
        state.isFetching = false
        state.error = (action.payload as string) || NetworkMessages.GetResidentDataError
      })
      .addCase(getResidentData.fulfilled, (state, action) => {
        state.error = null
        state.isFetching = false
        state.details = action.payload.details
        sessionStorage?.setItem(
          SessionStore.ResidentData,
          JSON.stringify({
            details: state.details,
          }),
        )
      })
  },
})

export const { cacheResidentDataFromSession } = residentData.actions
export default residentData.reducer
