import { useEffect } from 'react'

interface Args {
  errors: any
  isSubmitting: boolean
  isValidating: boolean
  isDesktop?: boolean
  isShowGeneralError?: boolean
}

export const scrollToError = ({ errors, isSubmitting, isValidating, isShowGeneralError, isDesktop }: Args): void => {
  useEffect(() => {
    if (isSubmitting && !isValidating && !isDesktop) {
      const keys = Object.keys(errors)
      let errorElement: HTMLElement | null = null
      if (keys.length > 0) {
        errorElement = document.querySelector(`[name=${keys[0]}]`) as HTMLElement
      } else if (isShowGeneralError) {
        errorElement = document.querySelector('#errorCard') as HTMLElement
      }

      errorElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [errors, isSubmitting, isValidating, isShowGeneralError])
}
