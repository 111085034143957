import { MarketingProgramCode } from '@/constants'
import IDIQClient, { IDIQCreditPlugin, IDIQUserPlugin, IDIQPostbackPixelPlugin } from '@idiq-ppd/idiq-client'

const Client = IDIQClient.config({
  apiGatewayUrl: process.env.NEXT_PUBLIC_IDIQ_API_GATEWAY_URL as string,
  key: process.env.NEXT_PUBLIC_IDIQ_SERVICES_KEY as string,
  marketingProgramCode: MarketingProgramCode,
}).extend([IDIQCreditPlugin, IDIQUserPlugin, IDIQPostbackPixelPlugin])

const client = new Client()
const useIDIQServices = (): any => client
export default useIDIQServices

export interface IDIQError {
  code?: string
  message?: string
  redirect?: string
}
