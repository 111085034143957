import { useEffect } from 'react'
import { track } from '@/state/actions'
import { TrackingTriggerMapping } from '@/constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectPlanState, selectActivationState } from '@/state/selectors'

const useTracker = ({ trigger }: { trigger: TrackingTriggerMapping }): void => {
  const dispatch = useDispatch()
  const { details } = useSelector(selectPlanState)
  const { details: activationDetails } = useSelector(selectActivationState)

  useEffect(() => {
    if (details && !activationDetails) {
      dispatch(track({ trigger }))
    }
  }, [details])
}

export default useTracker
