import React, { FunctionComponent, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import {
  EnrollmentFormID,
  Labels,
  TERMS_URL,
  PRIVACYPOLICY_URL,
  Routes,
  PROVE_SERVER_URL,
  BrandName,
  TRUSTEDFORM_SCRIPT_URL,
  DefaultPhoneNumber,
  SessionStore,
} from '@/constants'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import ValidInputIcon from '../ValidInputIcon'
import { useStyles } from './styles'
import { validationSchema } from './validationSchema'
import { InitialValues, initialValues } from './initialValues'
import ErrorCard from '@/components/ErrorCard'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton from '../SubmitButton'
import { uuid } from '@/utils'
import InputMask from 'react-input-mask'
import { EnrollmentStep, Prefill, SMSProveAuthUrl, ProveInformation, TrustScore } from '@/state/types'
import { cacheAccountInfo, cacheIdentityInfo, setFormValid, setProveWorkflow } from '@/state/enrollment'
import LoadingIndicator from '../LoadingIndicator'
import { useRouter } from 'next/router'
import useQueryString from '../../hooks/useQueryString'
import SubHeader from '../SubHeader'
import ProgressStepper from '../ProgressStepper'
import { apm } from '@elastic/apm-rum'
import { selectPlanState, selectInvitationPlanState } from '@/state/selectors'
import { getInvitationPlan } from '@/state/actions'
import { isMobile } from 'react-device-detect'
import dynamic from 'next/dynamic'

const DiscountPrompt = dynamic(() => import('../DiscountPrompt'))
const ProgressIndicator = dynamic(() => import('../ProgressIndicator'))

let sessionId = uuid()
let phoneNumber = ''
let lastFourSSN = ''
let intervalVerifySession: any
let pendingVerifySession = false

const counterDefault = 300
const getTimerFromCounter = (counter: number) => {
  const min = ~~(counter / 60)
  const sec = counter % 60

  return min + ':' + (sec < 10 ? '0' + sec : sec)
}
let counter = counterDefault

const FormProveInfo: FunctionComponent = () => {
  const classes = useStyles()
  const [isSubmitting, setSubmitting] = useState(false)
  const [showConfirmLink, setConfirmLink] = useState(false)
  const [showRepeatSend, setShowRepeatSend] = useState(false)
  const [isGettingPrefill, setIsGettingPrefill] = useState(false)
  const [timer, setTimer] = useState(getTimerFromCounter(counter))
  const [error, setError] = useState('')
  const [verifySessionStarted, setVerifySessionStarted] = useState(false)
  const router = useRouter()
  const query = useQueryString()
  const dispatch = useDispatch()
  const plan = useSelector(selectPlanState)
  const invitationPlanState = useSelector(selectInvitationPlanState)

  apm.setCustomContext({ userAction: 'ProveWorkflow' })

  useEffect(() => {
    if (router?.query?.key && !invitationPlanState.isFetching && !invitationPlanState.details) {
      dispatch(getInvitationPlan({ key: router.query.key as string }))
    }
  }, [query, invitationPlanState])

  const shouldShowCPromptForDiscountCode =
    plan?.details?.isPromptForDiscountCode &&
    (!invitationPlanState.hasKey || (invitationPlanState.details && !invitationPlanState.details?.couponCode))

  dispatch(setProveWorkflow(true))

  const moveToDefaultWorkflow = () => {
    clearInterval(intervalVerifySession)
    dispatch(setProveWorkflow(null))
    dispatch(
      cacheIdentityInfo({
        proveSessionId: sessionId,
        isSaveOnly: true,
      }),
    )
    router.push({
      pathname: Routes.Enroll,
      query: router.query,
    })
  }

  const startTimer = () => {
    counter = counter <= 0 ? counterDefault : counter
    if (counter < counterDefault) {
      counter = counterDefault
      setTimer(getTimerFromCounter(counter))

      return
    }
    setTimer(getTimerFromCounter(counter))
    const interval = setInterval(() => {
      if (--counter <= 0) {
        clearInterval(interval)
      }
      setTimer(getTimerFromCounter(counter))
    }, 1000)
  }

  const activity = (activity: string, fields?: ProveInformation) => {
    const data = {
      sessionId: sessionId,
      phoneNumber: phoneNumber ? phoneNumber : fields?.phone?.replace(/\D/g, ''),
      last4SSN: lastFourSSN ? lastFourSSN : fields?.lastFourSSN?.replace(/\D/g, ''),
      activity: activity,
    }
    fetch(PROVE_SERVER_URL + '/v1/add-activity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).catch((e) => {
      setError(e)
      apm.captureError(e)
    })
  }

  const identityCheck = async () => {
    const data = {
      phoneNumber: phoneNumber,
      last4SSN: lastFourSSN,
      sessionId: sessionId,
    }
    fetch(PROVE_SERVER_URL + '/v1/pre-fill', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async (response: any) => {
        const result: Prefill = await response.json()
        if (result.status === 0) {
          dispatch(
            cacheAccountInfo({
              firstName: result.firstName,
              lastName: result.lastName,
              currentStreet: result.addresses && result.addresses[0]?.address,
              currentCity: result.addresses && result.addresses[0]?.city,
              currentState: result.addresses && result.addresses[0]?.region,
              currentZip: result.addresses && result.addresses[0]?.postalCode,
              trustedFormCertId: window.trustedForm?.id || '',
              isValid: true,
            }),
          )
          const dob = `${result.dob.split('-')[1]}/${result.dob.split('-')[2]}/${result.dob.split('-')[0]}`
          sessionStorage?.setItem(
            SessionStore.ProvePrefill,
            JSON.stringify({
              firstName: result.firstName,
              lastName: result.lastName,
              dateOfBirth: dob,
            }),
          )
          dispatch(
            cacheIdentityInfo({
              ssn: result.ssn,
              phone: phoneNumber,
              dateOfBirth: dob,
              isProvePrefill: true,
              proveSessionId: sessionId,
            }),
          )
          router.push({
            pathname: Routes.Identity,
            query: router.query,
          })
        } else {
          apm.captureError(response?.additionalInfo)
          moveToDefaultWorkflow()
        }
      })
      .catch((e) => {
        moveToDefaultWorkflow()
        apm.captureError(e)
      })
  }

  const trustCheck = async () => {
    setSubmitting(false)
    setIsGettingPrefill(true)
    const data = {
      phoneNumber: phoneNumber,
      last4SSN: lastFourSSN,
      sessionId: sessionId,
    }
    fetch(PROVE_SERVER_URL + '/v1/trust-score', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async (response: Response) => {
        const result: TrustScore = await response.json()
        if (result.status === 0) {
          if (plan.details?.provePrefillEnabled && result?.trustScore >= Number(plan.details?.proveTrustScoreMax)) {
            identityCheck()
          } else {
            activity('TrustScoreNotMet')
            moveToDefaultWorkflow()
          }

          dispatch(
            cacheIdentityInfo({
              isProveBypass:
                plan.details?.proveTrustScoreEnabled &&
                Number(plan.details?.proveByPassAuthTrustScore) > 0 &&
                result?.trustScore >= Number(plan.details?.proveByPassAuthTrustScore),
              trustScore: result?.trustScore,
              isSaveOnly: true,
            }),
          )
        } else {
          apm.captureError(result?.additionalInfo)
          moveToDefaultWorkflow()
        }
      })
      .catch((e) => {
        apm.captureError(e)
        moveToDefaultWorkflow()
      })
  }

  const checkingLinkClick = () => {
    setConfirmLink(true)
    startTimer()
    if (verifySessionStarted) {
      return
    }
    setVerifySessionStarted(true)
    intervalVerifySession = setInterval(() => {
      if (pendingVerifySession) {
        return
      }
      pendingVerifySession = true
      fetch(`${PROVE_SERVER_URL}/v1/verify-session/${sessionId}`, {
        method: 'GET',
      })
        .then(async (response: any) => {
          response = await response.json()
          if (response) {
            clearInterval(intervalVerifySession)
            if (isMobile) {
              router.push(Routes.AuthSuccess)

              return
            }
            if (plan.details?.proveTrustScoreEnabled) {
              trustCheck()
            } else {
              moveToDefaultWorkflow()
            }
          }
        })
        .finally(() => {
          pendingVerifySession = false
        })
        .catch((e) => {
          clearInterval(intervalVerifySession)
          apm.captureError(e)
          moveToDefaultWorkflow()
        })
    }, 2000)
  }

  const smsDelivery = (status: { statusCode: number; statusText: string }) => {
    if (status.statusCode === 0) {
      checkingLinkClick()
    } else {
      apm.captureError(status?.statusText)
      moveToDefaultWorkflow()
    }

    setSubmitting(false)
  }

  const getAuthUrl = (phone: string, ssn: string) => {
    dispatch(
      cacheIdentityInfo({
        proveSessionId: sessionId,
        isSaveOnly: true,
      }),
    )
    const data = {
      sessionId: sessionId,
      finalTargetUrl:
        window.location.origin +
        Routes.AuthSuccess +
        '?' +
        query.queryString +
        '&ismobile=' +
        isMobile +
        '&sessionid=' +
        sessionId +
        '&phone=' +
        phone +
        '&ssn=' +
        ssn,
      phoneNumber: phone,
      last4SSN: ssn,
      originCallerUrl: `${window.location.origin}/continueAuth`,
      BrandPhoneNumber: DefaultPhoneNumber,
      BrandName: BrandName,
    }
    setSubmitting(true)
    fetch(PROVE_SERVER_URL + '/v1/sms-auth-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async (response: Response) => {
        const result: SMSProveAuthUrl = await response.json()
        if (result.statusCode === 0) {
          phoneNumber = phone.replace(/\D/g, '')
          lastFourSSN = ssn
          smsDelivery(result)
        } else {
          apm.captureError(result?.statusText)
          moveToDefaultWorkflow()
        }
      })
      .catch((e) => {
        setError(e)
        apm.captureError(e)
        moveToDefaultWorkflow()
      })
  }

  const onSubmit = (fields: ProveInformation) => {
    if (showConfirmLink) {
      setShowRepeatSend(true)
    }
    getAuthUrl(fields.phone.replace(/\D/g, ''), fields.lastFourSSN)
  }

  const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid } = useFormik<InitialValues>({
    initialValues,
    onSubmit,
    validationSchema,
  })

  useEffect(() => {
    dispatch(setFormValid(isValid))
  }, [isValid])

  useEffect(() => {
    if (query.queryObject?.vfp && query.queryObject?.ismobile === 'true') {
      phoneNumber = query.queryObject?.phone
      lastFourSSN = query.queryObject?.ssn
      sessionId = query.queryObject?.sessionid
      dispatch(
        cacheIdentityInfo({
          proveSessionId: sessionId,
          isSaveOnly: true,
        }),
      )
      trustCheck()
    }
  }, [])

  if (plan.details?.trustedFormEnabled) {
    useEffect(() => {
      const script = document.createElement('script')
      script.src = TRUSTEDFORM_SCRIPT_URL + new Date().getTime() + Math.random()
      script.async = true
      script.type = 'text/javascript'
      document.body.appendChild(script)
    }, [])
  }

  if (isGettingPrefill) {
    return (
      <>
        <SubHeader>
          <ProgressStepper activeStep={EnrollmentStep.AccountInfo} />
        </SubHeader>
        <ProgressIndicator />
      </>
    )
  }

  if (isSubmitting && !error && !showConfirmLink) {
    return <LoadingIndicator status="progress" title="Loading..." description="Please wait..." />
  }

  return (
    <>
      <SubHeader>
        <ProgressStepper activeStep={EnrollmentStep.AccountInfo} />
      </SubHeader>
      <form onSubmit={handleSubmit} id={EnrollmentFormID} className={classes.form}>
        <Card className={classes.root} variant="outlined">
          {error && <ErrorCard>{error}</ErrorCard>}
          <img src={process.env.HEADER_LOGO_MOBILE} alt={BrandName} className={classes.idiqIcon} />

          {!showConfirmLink ? (
            <div className={classes.wrapContainer}>
              <h2 className={classes.title}>
                Let’s begin by
                <br /> finding your info.
              </h2>
              <p className={classes.desc}>
                We can pre-fill some of your info and
                <br /> speed up the enrollment process.
              </p>

              <Grid item>
                <InputMask
                  mask="(999) 999-9999"
                  value={values.phone}
                  disabled={false}
                  name="phone"
                  maskPlaceholder="(___) ___-____"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {() => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="phone"
                      label={Labels.ProvePhone}
                      id="phone"
                      autoComplete="tel"
                      error={touched.phone && !!errors.phone}
                      helperText={touched.phone && errors.phone}
                      classes={{
                        root: touched.phone && !errors.phone ? classes.validInput : '',
                      }}
                      InputProps={{
                        endAdornment: touched.phone && !errors.phone && <ValidInputIcon />,
                        classes: { notchedOutline: classes.notchedOutline },
                      }}
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item>
                <InputMask
                  mask="9999"
                  value={values.lastFourSSN}
                  disabled={false}
                  name="lastFourSSN"
                  maskPlaceholder="xxxx"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {() => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      name="lastFourSSN"
                      fullWidth
                      label={Labels.ProveLastFourSSN}
                      id="lastFourSSN"
                      data-tf-sensitive="true"
                      autoComplete="off"
                      error={touched.lastFourSSN && !!errors.lastFourSSN}
                      helperText={touched.lastFourSSN && errors.lastFourSSN}
                      classes={{
                        root: touched.lastFourSSN && !errors.lastFourSSN ? classes.validInput : '',
                      }}
                      InputProps={{
                        endAdornment: touched.lastFourSSN && !errors.lastFourSSN && <ValidInputIcon />,
                        classes: { notchedOutline: classes.notchedOutline },
                      }}
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item className={classes.submitButtonWrap}>
                <SubmitButton
                  onClick={handleSubmit}
                  text="Submit"
                  hideIcon={true}
                  disabled={!isValid || !touched.phone || (isSubmitting && !error)}
                />
              </Grid>
              <Grid item style={{ marginTop: '24px', textAlign: 'center' }}>
                <Button
                  className={classes.secondaryLink}
                  onClick={() => {
                    activity('NoMobile', values)
                    moveToDefaultWorkflow()
                  }}
                >
                  I don’t have a mobile phone
                </Button>
              </Grid>
            </div>
          ) : (
            <div className={classes.wrapContainerForCheck}>
              {counter ? (
                <div>
                  <h2 className={classes.title}>
                    {showRepeatSend && <div>A new link was sent.</div>}Check your phone.
                  </h2>
                  <p className={classes.desc}>
                    {`A text message with a link was sent to the phone number ending in ${phoneNumber
                      .replace(/\D/g, '')
                      .substr(phoneNumber.length - 4)}`}
                    .
                  </p>
                  <p className={classes.timerText}>Link will expire in: {timer}</p>
                </div>
              ) : (
                <div>
                  <h2 className={classes.titleExpired}>
                    <div>The link has expired.</div>
                    <div>Please try again.</div>
                  </h2>
                </div>
              )}
              <Button
                className={classes.secondaryLink}
                onClick={() => {
                  activity('NoSmsReceived')
                  moveToDefaultWorkflow()
                }}
              >
                Manually enter my information instead
              </Button>

              <p className={classes.secondaryDesc}>
                If you haven&apos;t received the link after a few minutes you can{' '}
                <Button
                  className={classes.secondaryLink}
                  onClick={() => {
                    activity('ResendLink')
                    onSubmit(values)
                  }}
                >
                  request a new link
                </Button>
              </p>
            </div>
          )}

          <div className={classes.termsLinks}>
            <Button
              onClick={() => window.open(TERMS_URL, 'Terms and Conditions', 'resizable')}
              className={classes.disclaimerLinks}
            >
              Terms and Conditions
            </Button>
            <span className={classes.separator}>|</span>
            <Button
              onClick={() => window.open(PRIVACYPOLICY_URL, 'Privacy Policy', 'resizable')}
              className={classes.disclaimerLinks}
            >
              Privacy Policy
            </Button>
          </div>
        </Card>
        {shouldShowCPromptForDiscountCode && <DiscountPrompt />}
      </form>
    </>
  )
}

export default FormProveInfo
