import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPlanState } from '@/state/selectors'
import { PlanState } from '@/state/plan'
import { getPlan } from '@/state/actions'

interface Props {
  effortCode: string
  planCode: string
  groupingCode?: string
}

const usePlan = ({ effortCode, planCode, groupingCode = '' }: Props): PlanState => {
  const dispatch = useDispatch()
  const planState = useSelector(selectPlanState)

  useEffect(() => {
    if (effortCode && planCode && !planState.details && !planState.isFetching) {
      dispatch(
        getPlan({
          effortCode,
          planCode,
          groupingCode,
        }),
      )
    }
  }, [effortCode, planCode, groupingCode])

  return planState
}

export default usePlan
