export enum CNCStatus {
  BeforeGetL3 = 'UA',
  BeforeSubmitL3 = 'L3',
  SuccessL3 = 'AS',
  ManualAuthComplete = 'MA',
  ManualAuthRequired = 'MP',
}

export enum VerificationStatus {
  Verified = 'Y',
  Unverified = 'N',
}

export interface CNCStatusObject {
  status: string
  cncStatus: string
}

export const AuthStatuses: Record<string, CNCStatusObject> = {
  Enrolled: {
    status: VerificationStatus.Unverified,
    cncStatus: CNCStatus.BeforeGetL3,
  },
  L3QuestionsLoaded: {
    status: VerificationStatus.Unverified,
    cncStatus: CNCStatus.BeforeSubmitL3,
  },
  ManualAuthRequired: {
    status: VerificationStatus.Verified,
    cncStatus: CNCStatus.ManualAuthRequired,
  },
  L3QuestionsAnswered: {
    status: VerificationStatus.Verified,
    cncStatus: CNCStatus.SuccessL3,
  },
}

export enum MemberStatus {
  ActivationPending = 'Activation Pending',
  VerificationPending = 'Verification Pending',
  Active = 'Active',
  Cancelled = 'Cancelled',
}
