import React, { FunctionComponent } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import theme, { stepperColor } from '@/theme'

const useStyles = makeStyles(() =>
  createStyles({
    dotsContainer: (props: StyleProp) => ({
      display: 'flex',
      justifyContent: 'center',
      margin: props.margin,
    }),
    dots: (props: StyleProp) => ({
      display: 'inlineBlock',
      width: props.width,
      height: props.height,
      background: props.background,
      borderRadius: '50%',
      marginRight: '4px',
    }),
    dot1: {
      animation: `$jumpy 1s linear infinite`,
    },
    dot2: {
      animation: `$jumpy 1s .3s linear infinite`,
    },
    dot3: {
      animation: `$jumpy 1s .6s linear infinite`,
    },
    '@keyframes jumpy': {
      '0%': {
        transform: 'translateY(0)',
      },
      '50%': {
        transform: 'translateY(-12px)',
      },
      '100%': {
        transform: 'translateY(0)',
      },
    },
  }),
)

interface StyleProp {
  margin: string
  background: string
  width: string
  height: string
}

const StyleProps: Record<string, StyleProp> = {
  modal: {
    margin: '0 0 45px 0',
    background: stepperColor,
    width: '15px',
    height: '15px',
  },
  quickview: {
    margin: '26px 0 12px 0',
    background: theme.palette.common.white,
    width: '10px',
    height: '10px',
  },
}
interface Props {
  variant: 'modal' | 'quickview'
}

const IconProgress: FunctionComponent<Props> = ({ variant }: Props) => {
  const classes = useStyles(StyleProps[variant])

  return (
    <div className={classes.dotsContainer}>
      <div className={cx(classes.dots, classes.dot1)}></div>
      <div className={cx(classes.dots, classes.dot2)}></div>
      <div className={cx(classes.dots, classes.dot3)}></div>
    </div>
  )
}

export default IconProgress
