// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
let globalHeap = {}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (typeof window !== 'undefined' && window.heap) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalHeap = window.heap
}

const handler = {
  get(target: never, propKey: string) {
    const originalMethod = target[propKey]

    return function (...args: never) {
      try {
        if (originalMethod) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return originalMethod.apply(this, args)
        } else {
          return
        }
      } catch (error) {
        return
      }
    }
  },
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const heap: Record<string, Function> = new Proxy(globalHeap, handler)
