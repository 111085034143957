import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { GetIndicativeEnrichmentReportResponse } from '@idiq-ppd/idiq-client/src/IDIQParser/types/GetIndicativeEnrichmentResponse'
import { getIndicativeEnrichmentReport } from './actions/getIndicativeEnrichmentReport'
import { NetworkMessages } from '@/constants'

export interface IndicativeEnrichmentState {
  isFetchingIdentity: boolean
  error: null | string
  indicativeEnrichmentReport?: GetIndicativeEnrichmentReportResponse
  isIndicativeEnrichmentFailed: boolean
  isIndicativeEnrichmentEnabled: boolean
  bypassSuccessScreen: boolean
}

const initialState: IndicativeEnrichmentState = {
  isFetchingIdentity: false,
  error: null,
  isIndicativeEnrichmentFailed: false,
  isIndicativeEnrichmentEnabled: false,
  bypassSuccessScreen: false,
}

const indicativeEnrichment: Slice = createSlice({
  name: 'indicativeEnrichment',
  initialState,
  reducers: {
    setBypassSuccess: (state, action: PayloadAction<{ bypassSuccessScreen: boolean }>) => {
      state.bypassSuccessScreen = !!action.payload.bypassSuccessScreen
    },
    setIndicativeEnrichmentStatus: (
      state,
      action: PayloadAction<{ isIndicativeEnrichmentEnabled: boolean; isIndicativeEnrichmentFailed: boolean }>,
    ) => {
      state.isIndicativeEnrichmentEnabled = action.payload.isIndicativeEnrichmentEnabled
      state.isIndicativeEnrichmentFailed = !!action.payload.isIndicativeEnrichmentFailed
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIndicativeEnrichmentReport.pending, (state) => {
        state.isFetchingIdentity = true
      })
      .addCase(getIndicativeEnrichmentReport.rejected, (state) => {
        state.isFetchingIdentity = false
        state.error = NetworkMessages.IndicativeEnrichmentFailedError
        state.isIndicativeEnrichmentFailed = true
      })
      .addCase(getIndicativeEnrichmentReport.fulfilled, (state, action) => {
        state.isFetchingIdentity = false
        state.indicativeEnrichmentReport = action.payload.indicativeEnrichment
      })
  },
})
export const { setIndicativeEnrichmentStatus, setBypassSuccess } = indicativeEnrichment.actions
export default indicativeEnrichment.reducer
