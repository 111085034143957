import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkAPI } from '../types'
import useIDIQServices from '../idiqServices'
import { recaptchaUtil } from '@/utils'

interface Args {
  recaptchaVerification: string
  secretKeyProperty: string
}

interface ReturnVal {
  recaptchaSuccess: boolean
  velocityByPass: boolean
}

const verifyRecaptchaRequest: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (
  args: Args,
  { rejectWithValue },
) => {
  try {
    const idiqServices = useIDIQServices()
    const response = await idiqServices.verifyRecaptcha(args)

    const { success = false } = response.data

    if (!success) {
      recaptchaUtil.reset()
    }

    return {
      recaptchaSuccess: success,
      velocityByPass: success,
    } as ReturnVal
  } catch ({ message }) {
    recaptchaUtil.reset()

    return rejectWithValue(message)
  }
}

export const verifyRecaptcha = createAsyncThunk<ReturnVal, Args, ThunkAPI>(
  'user/verifyRecaptcha',
  verifyRecaptchaRequest,
)
