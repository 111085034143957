import { SessionStore } from '@/constants'
import { createSlice, Slice, PayloadAction } from '@reduxjs/toolkit'
import { velocityCheck, verifyRecaptcha } from './actions'

export interface RecaptchaState {
  recaptchaVerification: string | null
  isRecaptchaRequired: boolean
  recaptchaSuccess: boolean
  velocityByPass: boolean
  error: string | null
}

const initialState: RecaptchaState = {
  recaptchaVerification: null,
  isRecaptchaRequired: false,
  recaptchaSuccess: false,
  velocityByPass: false,
  error: null,
}

const recaptcha: Slice = createSlice({
  name: 'recaptcha',
  initialState,
  reducers: {
    cacheVelocityByPass: (state, action: PayloadAction<{ velocityByPass: boolean }>) => {
      state.velocityByPass = action.payload.velocityByPass
    },
    cacheRecaptchaVerification: (state, action: PayloadAction<{ recaptchaVerification: string }>) => {
      state.recaptchaVerification = action.payload.recaptchaVerification
    },
    cacheRecaptchaRequired: (state, action: PayloadAction<{ isRecaptchaRequired: boolean }>) => {
      state.isRecaptchaRequired = action.payload.isRecaptchaRequired
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyRecaptcha.rejected, (state, action) => {
        state.recaptchaSuccess = false
        state.velocityByPass = false
        state.error = action.payload as string
      })
      .addCase(verifyRecaptcha.fulfilled, (state, action) => {
        state.recaptchaSuccess = action.payload.recaptchaSuccess
        state.velocityByPass = action.payload.velocityByPass
      })
      .addCase(velocityCheck.fulfilled, (state, action) => {
        state.isRecaptchaRequired = action.payload.isRecaptchaRequired
        sessionStorage?.setItem(SessionStore.IsRecaptchaRequired, String(action.payload.isRecaptchaRequired))
      })
  },
})

export default recaptcha.reducer
export const { cacheVelocityByPass, cacheRecaptchaVerification, cacheRecaptchaRequired } = recaptcha.actions
