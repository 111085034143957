import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { TrackingTriggerMapping } from '@/constants'
import { RootState, ThunkAPI } from '../types'
import { selectEnrollmentState, selectPlanState, selectTrackingState } from '@/state/selectors'
import useIDIQServices from '@/state/idiqServices'

interface Args {
  trigger: TrackingTriggerMapping
  membershipId?: string
}

type TrackResult = void | { trackingId: number }

const trackRequest: AsyncThunkPayloadCreator<TrackResult, Args, ThunkAPI> = async (
  args: Args,
  { getState, rejectWithValue },
) => {
  const state = getState() as RootState
  const tracking = selectTrackingState(state)
  const plan = selectPlanState(state)
  const enrollment = selectEnrollmentState(state)
  const idiqServices = useIDIQServices()
  const membershipId = enrollment.enrolledMember?.membershipNumber || args.membershipId

  /*
  if (!tracking.query?.queryObject?.transactionid && !tracking.query?.queryObject?.transaction_id) {
    return
  }
  */

  try {
    if (args.trigger == tracking.trigger && tracking.trackUrl) {
      const response = await idiqServices.trackMemberAfterCreate({
        offerCode: plan.effortCode,
        planCode: plan.details?.planCode,
        partnerCode: plan.details?.partnerCode,
        queryString: tracking.query?.queryString || '',
        price: plan.details?.price || '',
        trackingUrl: tracking.trackUrl,
        membershipNo: membershipId || '',
      })

      return {
        trackingId: response.data.trackingId as number,
      }
    }
  } catch (error) {
    return rejectWithValue({})
  }
}

export const track = createAsyncThunk<TrackResult, Args, ThunkAPI>('tracking/track', trackRequest)
