export const Title = process.env.BRAND_NAME

export enum NetworkMessages {
  AccountCodeMissing = 'Account code is missing',
  GetPlanError = 'There was an error retrieving plan details',
  EnrollmentError = 'There was an error enrolling',
  VerificationQuestionError = 'There was an error retrieving verification questions',
  VerificationAnswerError = 'There was a problem with your verification questions',
  RequireManualAuth = 'Manual Authentication is required at this time. Please call (999) 999-9999 for additional assistance',
  RedirectError = 'There was an error redirecting',
  IndicativeEnrichmentFailedError = 'We couldn’t confirm your identity using the last 4 of your SSN. Please enter your full SSN to continue',
  GetActivationError = 'There was an activation error',
  GetResidentDataError = 'There was an error retrieving resident data',
}

export enum Labels {
  CCV = 'CVV*',
  City = 'City*',
  ConfirmSSN = 'Confirm SSN',
  CreditCardNumber = 'Credit Card Number*',
  DateOfBirth = 'Date of Birth*',
  Email = 'Email*',
  FamilyEmail = 'Email',
  ExpirationDate = 'Expiration Date*',
  FirstName = 'First Name*',
  MiddleInitial = 'MI',
  IsBillingHomeAddress = 'My billing address is the same as my home address.',
  LastFourSSN = 'Last Four of SSN*',
  ProveLastFourSSN = 'Last 4 digits of your SSN*',
  LastName = 'Last Name*',
  Password = 'Password*',
  Phone = 'Phone Number*',
  ProvePhone = 'Mobile Phone Number*',
  PreviousAddressCheckBox = 'I have been at my current address for six months or more.',
  Street = 'Address*',
  State = 'State',
  SSN = 'SSN*',
  Zip = 'ZIP*',
  CouponCode = 'Coupon Code',
  DiscountCode = 'Enter Discount Code',
}

export enum Headings {
  AccountInformation = 'Account Information',
  BillingInformation = 'Billing Information',
  CurrentAddress = 'Current Address',
  PreviousAddress = 'Previous Address',
  VerifyIdentity = 'Identity Information',
  PurchasePlan = 'View Your Credit File',
  CreateAccount = 'Additional Account Information',
  PromoCode = 'PROMO CODE',
  FamilyMemberInvitation = 'Family Member Invitation',
  TelephoneInformation = 'Telephone',
  AddOns = 'Add Ons',
  AddOnsInfo = 'We think the following features would complement the plan you chose.',
  PrefillVerify = 'Make Sure Everything is Correct',
  CompleteSignUp = 'Complete Your Sign Up',
  DarkWebExposures = 'Dark Web Exposures',
  YourCreditInfo = 'Your Credit Info',
  ExposedPersonalDataInfo = 'Exposed personal data can fuel identity theft and fraud, negatively impacting your credit. <b>See your full exposure once enrolled.</b>',
}

export enum Subheadings {
  OpenAccounts = 'Open Accounts',
  NegativeAccounts = 'Negative Accounts',
  HardInquiries = 'Hard Inquiries',
  CreditUtilization = 'Credit Utilization',
  DataBreaches = 'Data Breaches',
}

export enum Tooltips {
  DataBreachAccounts = 'These are the website accounts associated with your email address that have had a data breach.',
  OpenAccounts = 'These accounts are associated with your credit report, like your credit card or student loan.',
  NegativeAccounts = 'These accounts are not in good standing, which can be due to factors such as late payments or bankruptcies.',
}

export enum Copy {
  SSNAgreement = 'Your social security number and DOB are used to confirm your identity. This info will be securely transmitted to the credit reporting agencies.',
  QuickviewCard = 'Here’s a <b>Quick Look</b> at <b>Your Credit</b> as of  ',
  QuickviewCardError = 'You’ve got one more step before you can access your credit data from  ',
  FieldsErrorMessage = 'Please correct the field errors in order to continue.',
  UnexpectedErrorMessage = 'There was an unexpected error. Please try again.',
  L3AnswersRejected = 'Identity verification failed. Please try again.',
  VerificationFailed = 'We are unable to verify your identity. Please call support and verify manually.',
  SubscribeSMS = 'Send me identity and credit monitoring alerts via SMS, if available.',
}

export const LoadingIndicatorMessages = {
  paymentProcessing: {
    title: 'Processing your payment',
    description: 'We are processing your order. This may take a moment...',
  },
  paymentConfirmed: {
    title: 'Payment confirmed',
    description: 'Your payment was successful!  Redirecting you...',
  },
  retrievingQuestions: {
    title: 'Retrieving Identity Verification Questions',
    description: 'We are retrieving your identity verification questions...',
  },
  verifyingAnswers: {
    title: 'Verifying your answers',
    description: 'We are verifying your security answers and preparing your credit report...',
  },
  verifyingConfirmed: {
    title: 'You are verified',
    description: "You're all set! Redirecting you to your dashboard...",
  },
  confirmingIdentity: {
    title: 'Confirming your idenitity',
    description: 'We are processing your information. This may take a moment...',
  },
  identityConfirmed: {
    title: 'Identity confirmed',
    description: 'Your identity has been confirmed. Requesting your information.',
  },
  activationProcessing: {
    title: 'Activating your account',
    description: 'We are activating your account. This may take a moment...',
  },
  activationCompleted: {
    title: 'Activation completed',
    description: 'Your activation was successful!  Redirecting you...',
  },
  noAccountOrderProcessing: {
    title: 'Processing your order',
    description: 'We are processing your order. This may take a moment...',
  },
  noAccountOrderConfirmed: {
    title: 'Order confirmed',
    description: 'Your order was successful!  Redirecting you...',
  },
}
