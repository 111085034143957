import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { InvitationPlan, ThunkAPI } from '../types'
import useIDIQServices from '../idiqServices'
import { apm } from '@elastic/apm-rum'
import router from 'next/router'
import { Routes } from '@/constants'

interface Args {
  key?: string
}

interface ReturnVal {
  details: InvitationPlan
}

const getInvitationPlanRequest: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (
  args: Args,
  { rejectWithValue },
) => {
  try {
    const { key } = args
    const idiqServices = useIDIQServices()
    const response = await idiqServices.getMembershipInvitationPlan(key)

    const code: string | null | undefined = response.data?.couponCode
    if (!code) {
      router.replace(Routes.TechError)
    }

    return {
      details: response.data,
    } as ReturnVal
  } catch (error) {
    apm.captureError(new Error(error as string))
    router.push(Routes.TechError, Routes.TechError)

    return rejectWithValue({})
  }
}

export const getInvitationPlan = createAsyncThunk<ReturnVal, Args, ThunkAPI>(
  'user/getInvitationPlan',
  getInvitationPlanRequest,
)
