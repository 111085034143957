import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectActivationState } from '@/state/selectors'
import { ActivationState } from '@/state/activation'
import { getActivation } from '@/state/actions'

const useActivation = (activationKey: string | undefined): ActivationState => {
  const dispatch = useDispatch()
  const activationState = useSelector(selectActivationState)

  useEffect(() => {
    if (activationKey && !activationState.details && !activationState.isFetching) {
      dispatch(getActivation({ activationKey }))
    }
  }, [activationKey])

  return activationState
}

export default useActivation
