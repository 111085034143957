import { createSlice, Slice } from '@reduxjs/toolkit'
import { getInvitationPlan } from './actions'
import { NetworkMessages } from '@/constants'
import { InvitationPlan } from '@/state/types'

export interface InvitationPlanState {
  error: null | string
  isFetching: boolean
  hasKey: boolean
  details?: InvitationPlan
}

const initialState: InvitationPlanState = {
  error: null,
  isFetching: false,
  hasKey: false,
}

const invitationPlan: Slice = createSlice({
  name: 'invitationPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvitationPlan.pending, (state) => {
        state.error = null
        state.isFetching = true
        state.hasKey = true
      })
      .addCase(getInvitationPlan.rejected, (state, action) => {
        state.error = (action.payload as string) || NetworkMessages.GetPlanError
      })
      .addCase(getInvitationPlan.fulfilled, (state, action) => {
        state.error = null
        state.isFetching = false
        state.details = action.payload.details
      })
  },
})

export default invitationPlan.reducer
