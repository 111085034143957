import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import { PlayArrow } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

type Props = {
  className?: string
}

const useStyles = makeStyles((theme) => ({
  '@keyframes pulse-animation': {
    from: { boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.4)' },
    to: { boxShadow: '0 0 0 16px rgba(255, 255, 255, 0)' },
  },

  container: {
    width: '90px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },

  iconWrapper: {
    animation: '$pulse-animation 2s infinite',
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '6px',
    position: 'relative',
    overflow: 'hidden',

    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
}))

const AnimatedPlayButton: FunctionComponent<Props> = ({ className }: Props) => {
  const classes = useStyles()

  return (
    <div className={cx(className, classes.container)}>
      <div className={classes.iconWrapper}>
        <PlayArrow fontSize={'medium'} />
      </div>
    </div>
  )
}

export default AnimatedPlayButton
