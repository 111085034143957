import * as Yup from 'yup'
import {
  isNotMatchInvitationEmail,
  isOptionalEmailValidAndDoesNotMatch,
  isValidCity,
  isValidFamilyEmail,
  isValidMiddleInitial,
  isValidName,
  isValidPassword,
  isValidState,
  isValidStreetAddress,
  isValidZip,
  validateUnless,
} from '../../utils/validators'
import { ValidationErrors } from '@/constants'

export const validationSchema = Yup.object({
  firstName: isValidName.required(ValidationErrors.Required),
  middleInitial: isValidMiddleInitial,
  lastName: isValidName.required(ValidationErrors.Required),
  email: isNotMatchInvitationEmail.required(ValidationErrors.Required),
  password: isValidPassword.required(ValidationErrors.Required),
  currentStreet: isValidStreetAddress.required(ValidationErrors.Required),
  currentCity: isValidCity.required(ValidationErrors.Required),
  currentState: isValidState.required(ValidationErrors.Required),
  currentZip: isValidZip.required(ValidationErrors.Required),
  isCurrentAddress: Yup.boolean(),
  isTermsAgree: Yup.boolean().oneOf([true], ValidationErrors.TermsRequired),
  previousStreet: validateUnless('isCurrentAddress', isValidStreetAddress.required(ValidationErrors.Required)),
  previousCity: validateUnless('isCurrentAddress', isValidCity.required(ValidationErrors.Required)),
  previousState: validateUnless('isCurrentAddress', isValidState.required(ValidationErrors.Required)),
  previousZip: validateUnless('isCurrentAddress', isValidZip.required(ValidationErrors.Required)),
  familyInvitationEmail: isValidFamilyEmail,
})
