import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, ThunkAPI } from '../types'
import useIDIQServices from '../idiqServices'
import { selectEnrollmentState } from '../selectors'

const updateUserStatusRequest: AsyncThunkPayloadCreator<void, Args, ThunkAPI> = async (
  args: Args,
  { rejectWithValue, getState },
) => {
  try {
    const idiqServices = useIDIQServices()
    const enrollmentState = selectEnrollmentState(getState() as RootState)
    const memberId = enrollmentState.enrolledMember?.membershipNumber

    const { membershipNumber = memberId, status, cncStatus } = args

    idiqServices.updateUserStatus({
      membershipNo: membershipNumber,
      status,
      cncStatus,
    })
  } catch (error) {
    return rejectWithValue((error as Error)?.message)
  }
}

export const updateUserStatus = createAsyncThunk<void, Args, ThunkAPI>('user/updateUser', updateUserStatusRequest)

export interface Args {
  membershipNumber?: string
  status: string
  cncStatus: string
}
