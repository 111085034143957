import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { getPlan, getDefaultPlan } from './actions'
import { NetworkMessages, SessionStore } from '@/constants'
import { Plan } from '@/state/types'

export interface PlanState {
  error: null | string
  isFetching: boolean
  details?: Plan
  effortCode?: string
}

const initialState: PlanState = {
  error: null,
  isFetching: false,
}

const plan: Slice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    cachePlanInfoFromSession: (state, action: PayloadAction<PlanState>) => {
      state.details = action.payload.details
      state.effortCode = action.payload.effortCode
      state.error = null
      state.isFetching = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlan.pending, (state) => {
        state.error = null
        state.isFetching = true
      })
      .addCase(getPlan.rejected, (state, action) => {
        state.error = (action.payload as string) || NetworkMessages.GetPlanError
      })
      .addCase(getPlan.fulfilled, (state, action) => {
        state.error = null
        state.isFetching = false
        state.details = action.payload.details
        state.effortCode = action.payload.effortCode
        sessionStorage?.setItem(
          SessionStore.PlanInfo,
          JSON.stringify({
            effortCode: state.effortCode,
            details: state.details,
          }),
        )
      })
      .addCase(getDefaultPlan.pending, (state) => {
        state.error = null
        state.isFetching = true
      })
      .addCase(getDefaultPlan.rejected, (state, action) => {
        state.isFetching = false
        state.error = (action.payload as string) || NetworkMessages.GetPlanError
      })
      .addCase(getDefaultPlan.fulfilled, (state, action) => {
        state.error = null
        state.isFetching = false
        state.details = action.payload.details
        state.effortCode = action.payload.effortCode
        sessionStorage?.setItem(
          SessionStore.PlanInfo,
          JSON.stringify({
            effortCode: state.effortCode,
            details: state.details,
          }),
        )
      })
  },
})

export const { cachePlanInfoFromSession } = plan.actions
export default plan.reducer
