import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, ThunkAPI } from '../types'

interface Args {
  ssn?: string
}

interface ReturnVal {
  isRecaptchaRequired: boolean
}
interface ApiResponse {
  isValid: boolean
}

const velocityCheckRequest: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (args: Args, { getState }) => {
  try {
    const state = getState() as RootState
    const { plan } = state

    const requestUrl = `${process.env.NEXT_PUBLIC_API_URL}/member-service/v1/gateway/velocity-check`
    const response = await fetch(requestUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ssn: args.ssn || null,
        offerCode: plan.effortCode,
      }),
    })
    const responseData: ApiResponse = await response.json()

    return {
      isRecaptchaRequired: !responseData.isValid,
    } as ReturnVal
  } catch (error) {
    return {
      isRecaptchaRequired: false,
    }
  }
}

export const velocityCheck = createAsyncThunk<ReturnVal, Args, ThunkAPI>('user/velocityCheck', velocityCheckRequest)
