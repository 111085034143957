import React, { FunctionComponent } from 'react'
import { GAKey, GTMKey } from '@/constants'
import Script from 'next/script'

const GtmSource = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${GTMKey}');
`

const GtmInitSource = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
`

const GtmScript: FunctionComponent = () => {
  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: GtmInitSource }} />

      <Script strategy={'afterInteractive'} dangerouslySetInnerHTML={{ __html: GtmSource }} />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${GAKey}`} />

      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${GTMKey}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  )
}

export default GtmScript
