const notInclude: string[] = ['offercode', 'membershipno', 'plancode', 'addon']
const regex = new RegExp(notInclude.join('|'), 'i')
export const parseQueryStringTrackInfo = (qryString?: string): string =>
  (qryString ?? '')
    .replace(/,/g, '~')
    .split('&')
    .filter((param) => {
      const keyValuePair = param.split('=')

      return !(regex.test(keyValuePair[0]) || keyValuePair.length == 1 || keyValuePair[1].length == 0)
    })
    .join(',')
