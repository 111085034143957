type ParsedParams = Partial<Record<string, string>>

const parse = <T = ParsedParams>(query: string): T => {
  return Object.fromEntries(new URLSearchParams(query)) as T
}

type StringifyParams = Record<string, string | number | boolean | null | undefined>

const stringify = (params: StringifyParams): string => {
  const entries = Object.entries(params)
    .filter(([, value]) => value != null)
    .map(([key, value]) => [key, String(value)])

  return new URLSearchParams(entries).toString()
}

export const qs = { parse, stringify }
