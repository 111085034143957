const shouldIgnore = (queryValue: string): boolean => queryValue.startsWith('{')

export const mapQueryToState = (query: Record<string, string>) => {
  const map: Record<string, string> = {
    fname: 'firstName',
    lname: 'lastName',
    email: 'email',
    address: 'currentStreet',
    city: 'currentCity',
    state: 'currentState',
    zip: 'currentZip',
    prevaddress: 'previousStreet',
    prevcity: 'previousCity',
    prevstate: 'previousState',
    prevzip: 'previousZip',
    cart: 'showcart',
    semail: 'familyInvitationEmail',
  }

  return Object.keys(map).reduce((acc: Record<string, string>, key: string) => {
    const stateKey = map[key]
    const queryValue = query[key] || ''

    if (shouldIgnore(queryValue)) {
      return acc
    }

    acc[stateKey] = stateKey === map.state || stateKey === map.prevstate ? queryValue.toLocaleUpperCase() : queryValue

    return acc
  }, {})
}
