import React, { FunctionComponent, ReactNode } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subHeader: {
      display: 'flex',
      justifyContent: 'center',
      height: '110px',
      padding: '0 35px 0',
      [theme.breakpoints.up('md')]: {
        height: '160px',
      },
    },
  }),
)

type Props = {
  children: ReactNode
}

const SubHeader: FunctionComponent<Props> = ({ children }: Props) => {
  const classes = useStyles()

  return <div className={classes.subHeader}>{children}</div>
}

export default SubHeader
