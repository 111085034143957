import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { SessionStore, TrackingTriggerMapping } from '@/constants'
import { UseQueryResult } from '../hooks/useQueryString'
import { track } from './actions/track'

export interface TrackingState {
  query: null | UseQueryResult
  trackUrl: null | string
  trackingId: null | number
  trigger: null | TrackingTriggerMapping
  fullUrl: null | string
}

const initialState: TrackingState = {
  query: null,
  trackUrl: null,
  trackingId: null,
  trigger: null,
  fullUrl: null,
}

const tracking: Slice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    loadTrigger: (state, action) => {
      state.trigger = action.payload.trigger
      state.trackUrl = action.payload.trackUrl
      sessionStorage?.setItem(SessionStore.Trigger, String(state.trigger))
      sessionStorage?.setItem(SessionStore.TrackUrl, String(state.trackUrl))
    },
    loadQuery: (state, action: PayloadAction<{ query: UseQueryResult }>) => {
      state.query = action.payload.query
      sessionStorage?.setItem(SessionStore.Query, JSON.stringify(state.query))
    },
    loadFullUrl: (state, action: PayloadAction<{ url: string }>) => {
      state.fullUrl = action.payload.url
      sessionStorage?.setItem(SessionStore.FullUrl, String(state.fullUrl))
    },
    loadTrackUrl: (state, action: PayloadAction<{ trackUrl: string }>) => {
      state.trackUrl = action.payload.trackUrl
      sessionStorage?.setItem(SessionStore.TrackUrl, String(state.trackUrl))
    },
    cacheTrackingId: (state, action: PayloadAction<{ trackingId: number }>) => {
      state.trackingId = state.trackingId ? state.trackingId : (action.payload?.trackingId as number) || null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(track.fulfilled, (state, action) => {
      state.trackingId = state.trackingId ? state.trackingId : (action.payload?.trackingId as number) || null
      sessionStorage?.setItem(SessionStore.TrackingId, String(state.trackingId))
    })
  },
})

export const { loadTrigger, loadQuery, loadFullUrl, loadTrackUrl, cacheTrackingId } = tracking.actions
export default tracking.reducer
