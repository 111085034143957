import React, { FunctionComponent } from 'react'
import { Headings } from '@/constants'
import { Card } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useStyles } from './styles'
import { H2 } from '../Headings'

const AccountInfoSkeleton: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <>
      <Card className={classes.root} variant="outlined">
        <H2 className={classes.title}>{Headings.AccountInformation}</H2>
        <div className={classes.groupedSkeletonFields}>
          <Skeleton className={classes.firstName} variant="rect" height={60} />
          <Skeleton className={classes.lastName} variant="rect" height={60} />
        </div>
        <div className={classes.groupedSkeletonFields}>
          <Skeleton className={classes.firstName} variant="rect" height={60} />
          <Skeleton className={classes.lastName} variant="rect" height={60} />
        </div>
        <H2 className={classes.title}>{Headings.CurrentAddress}</H2>
        <div className={classes.groupedSkeletonFields}>
          <Skeleton className={classes.firstName} variant="rect" height={60} />
          <Skeleton className={classes.lastName} variant="rect" height={60} />
        </div>
        <div className={classes.groupedSkeletonFields}>
          <Skeleton className={classes.firstName} variant="rect" height={60} />
          <Skeleton className={classes.lastName} variant="rect" height={60} />
        </div>
        <Skeleton variant="text" />
      </Card>
    </>
  )
}

export default AccountInfoSkeleton
