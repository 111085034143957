import React, { ElementType, FunctionComponent, ReactNode } from 'react'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { CSSProperties } from '@material-ui/styles'

interface Props {
  variant?: 'body1' | 'body2' | 'subtitle1' | 'h1' | 'h2'
  component?: ElementType
  className?: string
  em?: boolean
  children: ReactNode
  style?: CSSProperties
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    em: {
      fontWeight: 500,
      color: palette.text.secondary,
    },
  }),
)

const P: FunctionComponent<Props> = ({ variant, component, className, children, em, style }: Props) => {
  const classes = useStyles()

  return (
    <Typography
      variant={variant}
      component={component || 'p'}
      style={style}
      className={cx(className, { [classes.em]: em })}
    >
      {children}
    </Typography>
  )
}

export default P
