import React, { FunctionComponent } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    checkmarkContainer: {
      animation: `$fadeIn .3s ease-in forwards`,
      marginBottom: '10px',
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: '0',
        transform: 'scale(1.7)',
      },
      '100%': {
        opacity: '1',
        transform: 'scale(1.2)',
      },
    },
  }),
)

const IconSuccess: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <div className={classes.checkmarkContainer}>
      <img src="/images/icon_success_checkmark.svg" alt="checkmark" />
    </div>
  )
}

export default IconSuccess
