import { useEffect } from 'react'
import useQueryString from './useQueryString'
import { useDispatch, useSelector } from 'react-redux'
import { cacheAccountInfo } from '@/state/enrollment'
import { mapQueryToState } from '@/utils'
import * as Yup from 'yup'
import {
  isValidCity,
  isValidEmailSync,
  isValidName,
  isValidState,
  isValidStreetAddress,
  isValidZip,
  validateWhen,
  isValidFamilyEmailSync,
} from '../utils/validators'
import { selectPlanState } from '@/state/selectors'
import { Routes } from '@/constants'
import { useRouter } from 'next/router'

interface Result {
  queryParams: Record<string, string>
}

const validationSchema = Yup.object({
  firstName: isValidName,
  lastName: isValidName,
  email: isValidEmailSync,
  currentStreet: isValidStreetAddress,
  currentCity: isValidCity,
  currentState: isValidState,
  currentZip: isValidZip,
  isFamilyPlan: Yup.boolean(),
  familyInvitationEmail: validateWhen('isFamilyPlan', isValidFamilyEmailSync),
})

const useAccountInfoParams = (): Result => {
  const dispatch = useDispatch()
  const { queryObject = {} } = useQueryString()
  const { details } = useSelector(selectPlanState)
  const router = useRouter()

  useEffect(() => {
    const query = mapQueryToState(queryObject)
    const {
      firstName,
      lastName,
      currentStreet,
      email,
      currentCity,
      currentState,
      currentZip,
      previousStreet,
      previousCity,
      previousState,
      previousZip,
      familyInvitationEmail,
    } = query
    const isCurrentAddress = !(previousStreet || previousCity || previousState || previousZip)

    validationSchema
      .isValid({ ...query, isFamilyPlan: !!familyInvitationEmail, isCurrentAddress })
      .then(async (isValid) => {
        if (firstName && lastName && currentStreet && currentCity && currentState && currentZip) {
          if (isValid && details?.isPlanAuthRequired) {
            await router.push({
              pathname: Routes.Identity,
              query: router.query,
            })
            dispatch(
              cacheAccountInfo({
                firstName,
                lastName,
                email,
                currentStreet,
                currentCity,
                currentState,
                currentZip,
                previousStreet,
                previousCity,
                previousState,
                previousZip,
                familyInvitationEmail,
                isValid,
                isPlanAuthRequired: details?.isPlanAuthRequired,
                isCurrentAddress,
              }),
            )
          }
        }
      })
  }, [])

  return {
    queryParams: mapQueryToState(queryObject),
  }
}

export default useAccountInfoParams
