import { apm } from '@elastic/apm-rum'
import router from 'next/router'
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { ResidentData, ThunkAPI } from '../types'
import { Routes, MemberStatus } from '@/constants'

interface Args {
  residentId: string
}

interface ReturnVal {
  details: ResidentData
}

const getResidentDataRequest: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (
  { residentId }: Args,
  { rejectWithValue },
) => {
  try {
    const requestUrl = `${process.env.NEXT_PUBLIC_API_URL}/rent-and-utility/v1/rent-reporting/resident/${residentId}`
    const response = await fetch(requestUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData: ResidentData = await response.json()

    if (responseData.memberStatus === MemberStatus.Active) {
      router.push(Routes.SignIn)
    } else if (responseData.memberStatus === MemberStatus.Cancelled) {
      router.push(Routes.Unauthorized)
    } else if (responseData.memberStatus || !response.ok) {
      router.push(Routes.TechError)
    }

    return {
      details: responseData,
    } as ReturnVal
  } catch (error) {
    apm.captureError(new Error(error as string))
    router.push(Routes.TechError, Routes.TechError)

    return rejectWithValue({})
  }
}

export const getResidentData = createAsyncThunk<ReturnVal, Args, ThunkAPI>(
  'user/getResidentData',
  getResidentDataRequest,
)
