import { DEFAULT_EFFORT, DEFAULT_PLANCODE } from '@/constants'

/**
 * If effortCode or planCode are invalid, return the default effortCode and planCode.
 * This could turn into something more significant later.
 */
export const parseQueryString = (query: Record<string, string>): Record<string, string> => {
  const effortCode = query.offercode && query.plancode ? query.offercode : DEFAULT_EFFORT
  const planCode = query.offercode && query.plancode ? query.plancode : DEFAULT_PLANCODE
  const groupingCode = query.groupingcode || ''
  const customPlanName = query.planname ? query.planname : ''
  const addOnCodes = query.addon ? query.addon : ''
  const interstitialAddOnCodes = [query.a1, query.a2, query.a3].join(',')

  return {
    effortCode,
    planCode,
    groupingCode,
    customPlanName,
    addOnCodes,
    interstitialAddOnCodes,
  }
}
