import React, { FunctionComponent, useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@/theme'
import PartnerLogo from './PartnerLogo'
import WarningBanner from '../WarningBanner'
import useQueryString from '../../hooks/useQueryString'
import { useSelector } from 'react-redux'
import { selectActivationState, selectEnrollmentState } from '@/state/selectors'
import { EnrollmentStep } from '@/state/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(12),
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      padding: '32px 15px 24px',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '1200px',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        padding: '32px 0 24px',
      },
      '& .headerLogosContainer': {
        display: 'flex',
        alignItems: 'center',
        '& picture': {
          display: 'flex',
        },
      },
      '&.newQvcardHeader': {
        alignItems: 'center',
        '& picture img': {
          maxHeight: 'none',
          height: '40px',
          verticalAlign: 'middle',
        },
      },
      '&.msiqHeader .headerLogosContainer img': {
        [theme.breakpoints.down('xs')]: {
          height: '25px',
        },
      },
      '&.csiqHeader .headerLogosContainer > img': {
        height: 33.5,
      },
      '&.cbiqHeader .headerLogosContainer > img': {
        height: 26,
      },
      '&.csiqHeader .headerLogosContainer picture img, &.cbiqHeader .headerLogosContainer picture img': {
        width: '224px',
        height: 'auto',
      },
      '&.csiqHeader .hasCobranding picture img': {
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
          height: '45px',
        },
      },
      '&.cbiqHeader .headerLogosContainer.hasCobranding img': {
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
          height: '31px',
        },
      },
    },
    brandLogo: {
      height: '31px',
    },
  }),
)

interface Props {
  businessLogo?: string
  pencilBannerEnabled?: boolean
}

const Header: FunctionComponent<Props> = ({ businessLogo, pencilBannerEnabled }: Props) => {
  const classes = useStyles()
  const query = useQueryString()
  const isIdentityIQ = process.env.THEME_NAME === 'IdentityIQ'
  const isMyscoreIQ = process.env.THEME_NAME === 'MyScoreIQ'
  const isCSIQ = process.env.THEME_NAME === 'CreditScoreIQ'
  const isCBIQ = process.env.THEME_NAME === 'CreditBuilderIQ'
  const { step } = useSelector(selectEnrollmentState)
  const { details: activationDetails } = useSelector(selectActivationState)
  const [isShowBanner, setIsShowBanner] = useState(false)

  useEffect(() => {
    setIsShowBanner(!query.queryObject?.activationkey && !activationDetails && step === EnrollmentStep.AccountInfo)
  }, [activationDetails, step])

  return (
    <>
      <div
        className={`${classes.header} ${isIdentityIQ && 'newQvcardHeader'} ${isMyscoreIQ && 'msiqHeader'} ${
          isCSIQ && 'csiqHeader'
        } ${isCBIQ && 'cbiqHeader'}`}
      >
        <div className={`headerLogosContainer ${!!businessLogo && 'hasCobranding'}`}>
          {isIdentityIQ ? (
            <picture>
              {!!businessLogo && (
                <source width="34" height="40" srcSet="./images/idiq_shield.svg" media="(max-width: 900px)" />
              )}
              <img
                width="204"
                height="40"
                src="./images/idiq_light.svg"
                alt="IdentityIQ"
                className={classes.brandLogo}
              />
            </picture>
          ) : (
            <picture>
              {!!businessLogo && <source srcSet={process.env.HEADER_LOGO_MOBILE} media="(max-width: 599px)" />}
              <img src={process.env.HEADER_LOGO} alt={process.env.HEADER_LOGO} className={classes.brandLogo} />
            </picture>
          )}
          <PartnerLogo businessLogo={businessLogo}></PartnerLogo>
        </div>
      </div>
      {isShowBanner && isIdentityIQ && pencilBannerEnabled && <WarningBanner />}
    </>
  )
}

export default Header
