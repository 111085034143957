import React, { FunctionComponent, ReactNode } from 'react'
import { default as MaterialDialog } from '@material-ui/core/Dialog'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@/theme'
import cx from 'classnames'
import { DialogContent } from '@material-ui/core'

const smPadding = 3
const mdPadding = 6

interface Props {
  classes?: {
    paper?: string
  }
  className?: string
  children: ReactNode
  open: boolean
  onClose?: () => void
}

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => {
  const unit = spacing(1)

  return createStyles({
    content: {
      overflow: 'hidden',
    },
    paper: {
      [breakpoints.down('sm')]: {
        padding: smPadding * unit,
        overflowY: 'inherit',
      },
      [breakpoints.up('md')]: {
        padding: spacing(mdPadding),
        overflowY: 'inherit',
      },
    },
  })
})

const Dialog: FunctionComponent<Props> = ({ classes, className, open, onClose, children }: Props) => {
  const { paper, content } = useStyles()

  return (
    <>
      <MaterialDialog
        open={open}
        classes={{
          paper: cx(paper, classes?.paper),
        }}
        className={cx(className)}
        onClose={onClose}
      >
        <DialogContent className={content}>{children}</DialogContent>
      </MaterialDialog>
    </>
  )
}

export default Dialog
