import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { getActivation } from './actions'
import { NetworkMessages, SessionStore } from '@/constants'
import { Activation } from '@/state/types'

export interface ActivationState {
  error: null | string
  isFetching: boolean
  details?: Activation
}

const initialState: ActivationState = {
  error: null,
  isFetching: false,
}

const activation: Slice = createSlice({
  name: 'activation',
  initialState,
  reducers: {
    cacheActivationInfoFromSession: (state, action: PayloadAction<ActivationState>) => {
      state.details = action.payload.details
      state.error = null
      state.isFetching = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivation.pending, (state) => {
        state.error = null
        state.isFetching = true
      })
      .addCase(getActivation.rejected, (state, action) => {
        state.error = (action.payload as string) || NetworkMessages.GetActivationError
      })
      .addCase(getActivation.fulfilled, (state, action) => {
        state.error = null
        state.isFetching = false
        state.details = action.payload.details
        sessionStorage?.setItem(
          SessionStore.ActivationInfo,
          JSON.stringify({
            details: state.details,
          }),
        )
      })
  },
})

export const { cacheActivationInfoFromSession } = activation.actions
export default activation.reducer
