/* eslint @typescript-eslint/ban-ts-comment: "off" */
import { GAKey } from '@/constants'

export const pageView = (url: string) => {
  // @ts-ignore
  window.gtag('config', GAKey, {
    page_path: url,
  })
}

export const event = (action: string, params?: Record<string, string>): void => {
  window.gtag('event', action, params)
}
