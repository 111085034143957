import { RootState } from '@/state/types'
import { PlanState } from '@/state/plan'
import { InvitationPlanState } from '@/state/invitationPlan'
import { EnrollmentState } from '@/state/enrollment'
import { VerificationState } from '@/state/verification'
import { TrackingState } from '@/state/tracking'
import { RecaptchaState } from '@/state/recaptcha'
import { IndicativeEnrichmentState } from '@/state/indicativeEnrichment'
import { AddOnsState } from '../addOns'
import { ActivationState } from '../activation'
import { ResidentDataState } from '@/state/residentData'
import { QuickviewState } from '@/state/quickview'
import { DarkWebState } from '@/state/darkWeb'

export const selectEnrollmentState = (state: RootState): EnrollmentState => state.enrollment
export const selectPlanState = (state: RootState): PlanState => state.plan
export const selectInvitationPlanState = (state: RootState): InvitationPlanState => state.invitationPlan
export const selectVerificationState = (state: RootState): VerificationState => state.verification
export const selectTrackingState = (state: RootState): TrackingState => state.tracking
export const selectRecaptchaState = (state: RootState): RecaptchaState => state.recaptcha
export const selectIndicativeEnrichmentState = (state: RootState): IndicativeEnrichmentState =>
  state.indicativeEnrichment
export const selectAddOnsState = (state: RootState): AddOnsState => state.addOns
export const selectActivationState = (state: RootState): ActivationState => state.activation
export const selectResidentDataState = (state: RootState): ResidentDataState => state.residentData
export const selectQuickviewState = (state: RootState): QuickviewState => state.quickview
export const selectDarkWebState = (state: RootState): DarkWebState => state.darkWeb
