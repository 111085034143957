import { AccountInformation, AccountInformationReadOnly } from '@/state/types'

export type InitialValues = AccountInformation &
  AccountInformationReadOnly & { isCurrentAddress: boolean; isTermsAgree: boolean }

export const initialValues: InitialValues = {
  firstName: '',
  middleInitial: '',
  lastName: '',
  email: '',
  password: '',
  currentStreet: '',
  currentCity: '',
  currentState: '',
  currentZip: '',
  isCurrentAddress: true,
  isTermsAgree: false,
  previousStreet: '',
  previousCity: '',
  previousState: '',
  previousZip: '',
  familyInvitationEmail: '',
}
