import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkAPI, RootState } from '../types'
import useIDIQServices from '../idiqServices'
import { GetIndicativeEnrichmentReportResponse } from '@idiq-ppd/idiq-client/src/IDIQParser/types/GetIndicativeEnrichmentResponse'
import { GetIndicativeEnrichmentReportData } from '@idiq-ppd/idiq-client/src/types/GetIndicativeEnrichmentReportData'
import { selectEnrollmentState, selectIndicativeEnrichmentState } from '../selectors'
import { setIndicativeEnrichmentStatus } from '../indicativeEnrichment'
import { returnToIdentity, cacheSSN } from '../enrollment'
import { heap } from '@/utils'
import { getQuickviewReport } from './getQuickviewReport'

interface ReturnVal {
  indicativeEnrichment?: GetIndicativeEnrichmentReportResponse
}

interface Args {
  lastFourSSN: string
  dateOfBirth: string
  phone: string
  subscribeSMS: boolean
}

const getIndicativeEnrichmentReportRequest: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (
  args: Args,
  { getState, rejectWithValue, dispatch },
) => {
  const { lastFourSSN, dateOfBirth, phone, subscribeSMS } = args

  try {
    const state = getState() as RootState
    const enrollmentState = selectEnrollmentState(state)
    const indicativeEnrichmentState = selectIndicativeEnrichmentState(state)

    if (indicativeEnrichmentState.indicativeEnrichmentReport) {
      return {
        indicativeEnrichment: indicativeEnrichmentState.indicativeEnrichmentReport,
      }
    }

    const { account } = enrollmentState
    const idiqServices = useIDIQServices()
    const request: GetIndicativeEnrichmentReportData = {
      address1: account.currentStreet,
      city: account.currentCity,
      state: account.currentState,
      zipCode: account.currentZip,
      dob: dateOfBirth,
      lastFourSSN,
      firstName: account.firstName,
      lastName: account.lastName,
    }

    const response = await idiqServices.getIndicativeEnrichmentReport(request)
    if (response.status && response.data.ssn) {
      const { ssn } = response.data
      dispatch(cacheSSN({ ssn }))
      dispatch(getQuickviewReport({ ssn, dateOfBirth }))
      heap.track(process.env.INDICATIVE_ENRICHMENT_SUCCESS)
    } else {
      heap.track(process.env.INDICATIVE_ENRICHMENT_FAILURE)
    }

    return {
      indicativeEnrichment: response.data,
    } as ReturnVal
  } catch ({ message }) {
    heap.track(process.env.INDICATIVE_ENRICHMENT_FAILURE)

    dispatch(
      setIndicativeEnrichmentStatus({ isIndicativeEnrichmentEnabled: false, isIndicativeEnrichmentFailed: true }),
    )
    dispatch(returnToIdentity({ dateOfBirth, phone, subscribeSMS }))

    return rejectWithValue(message)
  }
}

export const getIndicativeEnrichmentReport = createAsyncThunk<ReturnVal, Args, ThunkAPI>(
  'user/getIndicativeEnrichmentReport',
  getIndicativeEnrichmentReportRequest,
)
