import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { AuthStatuses, MaxVerificationAttempts, TrackingTriggerMapping } from '@/constants'
import { IDQuestionsResponseCodes, Question, RootState, ThunkAPI } from '../types'
import useIDIQServices from '../idiqServices'
import { getVerificationQuestions } from './getVerificationQuestions'
import { selectVerificationState } from '../selectors'
import { redirect } from '@/state/actions/redirect'
import { match } from '@/state/actions/match'
import { track } from '@/state/actions/track'

interface Args {
  answers: string[]
  memberId: string
  planCode: string
  offerCode: string
}

export interface VerificationResponse {
  responseCode: IDQuestionsResponseCodes
  newQuestions?: Question[]
  error?: string
}

function loadMoreQuestions(questions: any[]): any {
  return {
    responseCode: IDQuestionsResponseCodes.MoreQuestions,
    newQuestions: questions,
  }
}

function answersCorrect() {
  return {
    responseCode: IDQuestionsResponseCodes.Correct,
  }
}

function answersIncorrect() {
  return {
    responseCode: IDQuestionsResponseCodes.Incorrect,
  }
}

const submitVerificationQuestionsRequest: AsyncThunkPayloadCreator<VerificationResponse, Args, ThunkAPI> = async (
  args: Args,
  { rejectWithValue, dispatch, getState },
) => {
  let response
  const idiqServices = useIDIQServices()
  const state = getState() as RootState

  const { answers, memberId, planCode, offerCode } = args
  const verificationState = selectVerificationState(state)

  try {
    response = await idiqServices.submitVerification(memberId, {
      answers,
      planCode,
      offerCode,
    })

    switch (response.data.ResponseCode) {
      case IDQuestionsResponseCodes.Correct: {
        dispatch(track({ trigger: TrackingTriggerMapping.VerifySuccess }))
        dispatch(match({ membershipNumber: memberId, offerCode }))

        return answersCorrect()
      }
      case IDQuestionsResponseCodes.MoreQuestions:
        return loadMoreQuestions(response.data.question)
      case IDQuestionsResponseCodes.Incorrect:
        dispatch(getVerificationQuestions({ memberId, offerCode, planCode }))

        if (verificationState.verificationAttemptCount >= MaxVerificationAttempts) {
          dispatch(redirect({ authStatus: AuthStatuses.ManualAuthRequired }))
        }

        return answersIncorrect()
      case IDQuestionsResponseCodes.AccountCodeMissing:
      default:
        dispatch(redirect({ authStatus: AuthStatuses.ManualAuthRequired }))

        return {
          responseCode: response?.data?.ResponseCode,
        }
    }
  } catch ({ message }) {
    dispatch(redirect({}))

    return rejectWithValue(message)
  }
}

export const submitVerificationQuestions = createAsyncThunk<VerificationResponse, Args, ThunkAPI>(
  'member/submitVerification',
  submitVerificationQuestionsRequest,
)
