import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectResidentDataState } from '@/state/selectors'
import { ResidentDataState } from '@/state/residentData'
import { getResidentData } from '@/state/actions'

const useResidentData = (residentId: string | undefined): ResidentDataState => {
  const dispatch = useDispatch()
  const residentDataState = useSelector(selectResidentDataState)

  useEffect(() => {
    if (!!residentId && !residentDataState.details && !residentDataState.isFetching) {
      dispatch(getResidentData({ residentId }))
    }
  }, [residentId])

  return residentDataState
}

export default useResidentData
