/**
 * Parses the query string from window.location
 * This was done because useRouter returns undefined query strings during prerendering
 */
export interface UseQueryResult {
  queryString?: string
  queryObject?: Record<string, string>
}

export const cleanQueryString = (queryString: string): string =>
  queryString
    .replace('?', '')
    .split('&')
    .map((param) => {
      const keyValuePair = param.split('=')

      return keyValuePair.filter((str) => str.length > 0)
    })
    .filter((arr) => arr.length === 2)
    .map((arr) => arr.join('='))
    .join('&')

const useQueryString = (): UseQueryResult => {
  if (typeof window === 'undefined') {
    return {}
  }

  const queryString = cleanQueryString(window.location.search)

  const queryStringArray = queryString.split('&')

  const queryObject = queryStringArray.reduce((acc: Record<string, string>, query) => {
    const arr = query.split('=')
    const key = arr[0].toLowerCase()

    try {
      const value = decodeURIComponent(arr[1].replace(/\+/g, '%20')).replaceAll(/”/g, '').trim()

      acc[key] = value
    } catch (error) {
      acc[key] = ''
    }

    return acc
  }, {})

  return { queryObject, queryString }
}

export default useQueryString
