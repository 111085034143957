import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkAPI, RootState } from '../types'
import useIDIQServices from '../idiqServices'
import { GetQuickviewReportResponse } from '@idiq-ppd/idiq-client/src/IDIQParser/types/GetQuickviewResponseData'
import { GetQuickviewReportData } from '@idiq-ppd/idiq-client/src/types/GetQuickviewReportData'
import { selectEnrollmentState, selectQuickviewState, selectTrackingState, selectPlanState } from '../selectors'

interface ReturnVal {
  quickview?: GetQuickviewReportResponse
  disableQuickview: boolean
  hideQuickview?: boolean
}

interface Args {
  ssn?: string
  dateOfBirth?: string
}
const getQuickviewReportRequest: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (
  args: Args,
  { getState, rejectWithValue },
) => {
  try {
    const state = getState() as RootState
    const enrollmentState = selectEnrollmentState(state)
    const { ssn: ssnFromState, dateOfBirth: dobFromState } = enrollmentState.identity
    const { ssn = ssnFromState, dateOfBirth = dobFromState } = args
    const quickviewState = selectQuickviewState(state)
    const trackingState = selectTrackingState(state)
    const plan = selectPlanState(state)

    const isQuickViewReportEnabled = plan.details?.isQuickviewReportEnabled || false

    if (!(plan.details?.isDisplayQuickView || plan.details?.isDisplayIdentityQuickView)) {
      return {
        hideQuickview: true,
        disableQuickview: isQuickViewReportEnabled,
      }
    }

    if (trackingState.query?.queryObject?.qvoff == 'true' || !isQuickViewReportEnabled) {
      return {
        disableQuickview: true,
        hideQuickview: false,
      }
    }

    if (quickviewState.quickviewReport && isQuickViewReportEnabled) {
      return {
        quickview: quickviewState.quickviewReport,
        disableQuickview: false,
        hideQuickview: false,
      }
    }

    if (!ssn && plan.details?.isPlanAuthRequired) {
      return rejectWithValue('SSN is not available')
    }

    const { account } = enrollmentState

    const idiqServices = useIDIQServices()
    const request: GetQuickviewReportData = {
      address1: account.currentStreet,
      city: account.currentCity,
      state: account.currentState,
      zipCode: account.currentZip,
      dob: dateOfBirth || '',
      ssn: ssn || '',
      firstName: account.firstName,
      lastName: account.lastName,
    }
    const response = await idiqServices.getQuickviewReport(request)

    return {
      quickview: response.data,
      disableQuickview: false,
      hideQuickview: false,
    } as ReturnVal
  } catch (e) {
    const error = e as Error

    return rejectWithValue(error?.message)
  }
}

export const getQuickviewReport = createAsyncThunk<ReturnVal, Args, ThunkAPI>(
  'user/getQuickviewReport',
  getQuickviewReportRequest,
)
