import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { getVerificationQuestions, redirect, submitVerificationQuestions } from './actions'
import { Copy, NetworkMessages } from '@/constants'
import { EnrollmentInformation, IDQuestionsResponseCodes, Question } from './types'
import { VerificationResponse } from './actions/submitVerificationQuestions'

export interface VerificationState {
  error: null | string
  errorCode: null | IDQuestionsResponseCodes
  questions: null | Question[]
  isVerifying: boolean
  verifiedMember: boolean
  isFetching: boolean
  enrollmentInformation: EnrollmentInformation
  verificationAttemptCount: number
}

const initialState: VerificationState = {
  error: null,
  errorCode: null,
  isFetching: false,
  isVerifying: false,
  verifiedMember: false,
  questions: null,
  enrollmentInformation: {
    memberId: '',
    planCode: '',
    offerCode: '',
  },
  verificationAttemptCount: 0,
}

const verification: Slice = createSlice({
  name: 'verification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVerificationQuestions.pending, (state) => {
        state.isFetching = true
        state.error = null
      })
      .addCase(getVerificationQuestions.rejected, (state, action) => {
        state.isFetching = false
        state.error = (action.payload as string) || NetworkMessages.VerificationQuestionError
      })
      .addCase(getVerificationQuestions.fulfilled, (state, action) => {
        state.enrollmentInformation = action.meta.arg
        state.isFetching = false
        state.questions = action.payload as unknown as Question[]
      })
      .addCase(submitVerificationQuestions.pending, (state) => {
        state.isVerifying = true
        state.error = null
        state.errorCode = null
        state.verificationAttemptCount++
      })
      .addCase(submitVerificationQuestions.rejected, (state, action) => {
        state.isVerifying = false
        state.error = (action.payload as string) || NetworkMessages.VerificationAnswerError
        state.errorCode = IDQuestionsResponseCodes.Error
      })
      .addCase(submitVerificationQuestions.fulfilled, (state, action: PayloadAction<VerificationResponse>) => {
        state.isVerifying = false
        state.errorCode = action.payload?.responseCode

        switch (action.payload?.responseCode) {
          case IDQuestionsResponseCodes.Correct:
            state.verifiedMember = true
            state.error = null
            break
          case IDQuestionsResponseCodes.MoreQuestions:
            state.questions = action.payload?.newQuestions || []
            state.error = null
            break
          case IDQuestionsResponseCodes.Incorrect:
            state.error = Copy.L3AnswersRejected
            break
          default:
            state.error = Copy.UnexpectedErrorMessage
            break
        }
      })
      .addCase(redirect.rejected, (state, action) => {
        state.error = (action.payload as { error: string }).error
      })
  },
})

export default verification.reducer
