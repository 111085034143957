export const formatCurrency = (value: number, fractionDigits = 2): string => {
  return isFinite(value)
    ? new Intl.NumberFormat('en-US', {
        currency: 'USD',
        style: 'currency',
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      })?.format(value)
    : '—'
}
