import React, { FunctionComponent } from 'react'
import CartDesktop from './CartDesktop'
import useQueryString from '../../hooks/useQueryString'
import { parseQueryString } from '@/utils'
import { Plan } from '@/state/types'
import { addDays } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { selectAddOnsState, selectEnrollmentState, selectPlanState } from '@/state/selectors'
import { ErrorCodes } from '@/constants'
import { enrollMember } from '@/state/actions'
import { toggleAddOn } from '@/state/addOns'
import useAddOns from '../../hooks/useAddOns'

const initialPlanDetails: Plan = {
  displayName: '',
  planCode: '',
  defaultPrice: '',
  price: '',
  billingInterval: '',
  billingIntervalShort: '',
  benefits: {},
  trackingInfo: {},
  orderedBenefitsList: [],
  freeDays: '0',
  phone: '',
  partnerCode: '',
  coupon: [],
  isPlanAuthRequired: true,
  isEffortAuthRequired: true,
  enableKountService: false,
  proveMFAEnabled: false,
  proveTrustScoreEnabled: false,
  provePrefillEnabled: false,
  proveTrustScoreMax: '',
  proveTrustScoreMin: '',
  proveSMSTemplate: '',
  trustedFormEnabled: false,
  pencilBannerEnabled: false,
  proveByPassAuthTrustScore: 0,
  industryType: '',
}

const isNonTrialError = (errorCode?: string | null) => {
  return errorCode === ErrorCodes.GamerLogic || errorCode === ErrorCodes.IncludePrepaidRedirectOn
}

interface Props {
  isAccountInfo?: boolean
}

const CartContainer: FunctionComponent<Props> = ({ isAccountInfo }) => {
  const { queryObject = {} } = useQueryString()
  const { customPlanName } = parseQueryString(queryObject)
  const { details, isFetching } = useSelector(selectPlanState)

  useAddOns()

  const { errorCode, billing } = useSelector(selectEnrollmentState)
  const dispatch = useDispatch()
  const _details = details || initialPlanDetails
  const appliedCoupon = billing.appliedCoupon

  const { items: addOns, interstitialAddOns: allAddOns } = useSelector(selectAddOnsState)

  const trialDays = parseInt(_details.freeDays || '0')
  const isTrial = trialDays > 0 && !isNonTrialError(errorCode)
  const planName = customPlanName ? customPlanName : _details.displayName
  const trialEnd = addDays(new Date(), trialDays)
  const planBenefits = _details.orderedBenefitsList.slice(0, 5)
  const defaultPrice = _details.defaultPrice
  const displayPrice = isTrial ? _details.oneTimeFee || '0' : _details.price
  const planPrice = _details.price
  const isOneTimePurchase = Number(_details.oneTimeFee) > 0 && (Number(planPrice) === 0 || Number(defaultPrice) === 0)
  const billingInterval = _details.billingInterval
  const isConfirmationPanelRendering =
    errorCode === ErrorCodes.EnrollmentPending ||
    errorCode === ErrorCodes.GamerLogic ||
    errorCode === ErrorCodes.IncludePrepaidRedirectOn

  const handleReinstateSubmit = () => {
    if (isConfirmationPanelRendering) {
      dispatch(enrollMember())
    }
  }

  const onToggleAddOn = (code: string) => dispatch(toggleAddOn(code))

  return (
    <CartDesktop
      planIsFetching={isFetching}
      planName={planName}
      planBenefits={planBenefits}
      displayPrice={displayPrice || ''}
      defaultPrice={defaultPrice || ''}
      planPrice={planPrice}
      billingInterval={billingInterval}
      onReinstatePendingEnrollment={handleReinstateSubmit}
      appliedCoupon={appliedCoupon}
      isAccountInfo={isAccountInfo || false}
      isOneTimePurchase={isOneTimePurchase}
      addOns={addOns}
      allAddOns={allAddOns}
      trialEnd={trialEnd}
      trialDays={trialDays}
      onToggleAddOn={onToggleAddOn}
      isTrial={isTrial}
    />
  )
}

export default CartContainer
