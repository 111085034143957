import React, { FunctionComponent } from 'react'
import { PartnerLogoBaseUrl } from '@/constants'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      display: 'inline-flex',
      height: '31px',
      marginLeft: '36px',
    },
    qvcardLogo: {
      display: 'inline-flex',
      height: '40px',
      marginLeft: '36px',
    },
  }),
)

interface Props {
  businessLogo?: string
}

const PartnerLogo: FunctionComponent<Props> = ({ businessLogo }: Props) => {
  const classes = useStyles()
  const useNewQvcardStyles = process.env.THEME_NAME === 'IdentityIQ'

  return businessLogo ? (
    <img
      className={useNewQvcardStyles ? classes.qvcardLogo : classes.logo}
      src={`${PartnerLogoBaseUrl}${businessLogo}`}
      alt={`${PartnerLogoBaseUrl}${businessLogo}`}
    />
  ) : null
}

export default PartnerLogo
