import { MaxZIPLength, MaxCardLength, MaxCCVLength, MaxPromoCodeLength } from '@/constants'

export const limitZipCodeLength = (e: React.SyntheticEvent): void => {
  const zipInputElement = e.target as HTMLInputElement
  const value = zipInputElement.value
  //If true : make sure its under max length else prevent other char type event (since text input is used)
  zipInputElement.value = /^\d+$/.test(value) ? value.slice(0, MaxZIPLength) : value.slice(0, value.length - 1)
  //On Copy paste a string: limit the actual string with max length
  if (value.length > MaxZIPLength) {
    zipInputElement.value = value.slice(0, MaxZIPLength)
  }
}
export const limitPromoCodeLength = (e: React.SyntheticEvent): void => {
  const promoInputElement = e.target as HTMLInputElement
  const value = promoInputElement.value
  //If true : make sure its under max length else prevent other char type event (since text input is used)
  promoInputElement.value = /^[0-9a-zA-Z]/.test(value)
    ? value.slice(0, MaxPromoCodeLength).toUpperCase()
    : value.slice(0, value.length - 1).toUpperCase()
  //On Copy paste a string: limit the actual string with max length
  if (value.length > MaxPromoCodeLength) {
    promoInputElement.value = value.slice(0, MaxPromoCodeLength)
  }
}

export const limitCreditCardLength = (e: React.SyntheticEvent): void => {
  const cardNumberElement = e.target as HTMLInputElement
  const value = cardNumberElement.value
  //If true : make sure its under max length else prevent other char type event (since text input is used)
  cardNumberElement.value = /^\d+$/.test(value) ? value.slice(0, MaxCardLength) : value.slice(0, value.length - 1)
  //On Copy paste a string: limit the actual string with max length
  if (value.length > MaxCardLength) {
    cardNumberElement.value = value.slice(0, MaxCardLength)
  }
}

export const limitCCVLength = (e: React.SyntheticEvent): void => {
  const ccvInputElement = e.target as HTMLInputElement
  const value = ccvInputElement.value
  //If true : make sure its under max length else prevent other char type event (since text input is used)
  ccvInputElement.value = /^\d+$/.test(value) ? value.slice(0, MaxCCVLength) : value.slice(0, value.length - 1)
  //On Copy paste a string: limit the actual string with max length
  if (value.length > MaxCCVLength) {
    ccvInputElement.value = value.slice(0, MaxCCVLength)
  }
}
