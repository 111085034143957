import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit'
import plan from './plan'
import invitationPlan from './invitationPlan'
import enrollment from './enrollment'
import verification from './verification'
import tracking from './tracking'
import quickview from './quickview'
import recaptcha from './recaptcha'
import indicativeEnrichment from './indicativeEnrichment'
import addOns from './addOns'
import activation from './activation'
import residentData from './residentData'
import darkWeb from './darkWeb'
import { RootReducerState, RootState } from '@/state/types'

export const rootReducer = combineReducers<RootReducerState>({
  enrollment,
  plan,
  invitationPlan,
  verification,
  tracking,
  recaptcha,
  quickview,
  indicativeEnrichment,
  addOns,
  activation,
  residentData,
  darkWeb,
})

export const store = configureStore({
  reducer: rootReducer,
})

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
