export const DateFormat = process.env.DATE_FORMAT as string
export const DefaultPhoneNumber = process.env.DEFAULT_PHONENUMBER
export const EnrollmentFormID = process.env.ENROLLMENT_FORMID
export const ExpirationDateFormat = process.env.EXPIRATION_DATEFORMAT
export const GTMKey = process.env.NEXT_PUBLIC_GTM_KEY
export const HeapID = process.env.NEXT_PUBLIC_HEAP_ID
export const MarketingProgramCode = process.env.MARKETINGPROGRAM_CODE as string
export const MaxVerificationAttempts = Number(process.env.MAXVERIFICATION_ATTEMPTS ?? 0)
export const MaxZIPLength = Number(process.env.MAXZIP_LENGTH ?? 0)
export const MaxCardLength = Number(process.env.MAXCARD_LENGTH ?? 0)
export const MaxCCVLength = Number(process.env.MAXCCV_LENGTH ?? 0)
export const MaxPromoCodeLength = Number(process.env.MAXPROMOCODE_LENGTH ?? 0)
export const WebsiteCode = process.env.WEBSITE_CODE
export const ElasticServiceName = process.env.ELASTIC_SERVICENAME
export const ElasticServerUrl = process.env.NEXT_PUBLIC_ELASTIC_SERVERURL
export const PartnerLogoBaseUrl = ((process.env.NEXT_PUBLIC_PARTNER_LOGO_DOMAIN_URL as string) +
  process.env.PARTNERLOGO_URLPATH) as string
export const BrandName = process.env.BRAND_NAME as string
export const GoogleRecaptchaSitekey = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY as string
export const DEFAULT_EFFORT = process.env.EFFORT as string
export const DEFAULT_PLANCODE = process.env.PLANCODE as string

export const AnalyticsEvents = {
  eCommerce: process.env.ECOMMERCE_EVENTNAME,
}

export const GAKey = (process.env.GA_KEY || process.env.NEXT_PUBLIC_GA_KEY) as string
export const recaptchaSecretKeyProperty = process.env.RECAPTCHA_SECRET_KEY_PROPERTY
export const LOGIN_URL = ((process.env.NEXT_PUBLIC_SECURED_DOMAIN_URL as string) +
  (process.env.LOGIN_URLPATH as string)) as string
export const TERMS_URL = (process.env.NEXT_PUBLIC_DOMAIN_URL as string) + (process.env.TERMS_URLPATH as string)
export const PRIVACYPOLICY_URL =
  (process.env.NEXT_PUBLIC_DOMAIN_URL as string) + (process.env.PRIVACYPOLICY_URLPATH as string)
export const TCPA_URL = (process.env.NEXT_PUBLIC_DOMAIN_URL as string) + (process.env.TCPA_URLPATH as string)
export const PARTNERS_URL = (process.env.NEXT_PUBLIC_DOMAIN_URL as string) + (process.env.PARTNERS_URLPATH as string)
export const HOME_URL = process.env.NEXT_PUBLIC_DOMAIN_URL as string
export const INSTANT_LINK_SESSION_KEY = 'instant-link' as string
export const REPORT_URL = process.env.REPORT_URL as string
export const PROVE_LINK_URL = process.env.NEXT_PUBLIC_PROVE_LINK_URL as string
export const KOUNT_CLIENTID = process.env.NEXT_PUBLIC_KOUNT_CLIENTID as string
export const KOUNT_HOSTNAME = process.env.NEXT_PUBLIC_KOUNT_HOSTNAME as string
export const KOUNT_WEBSITECODE = process.env.NEXT_PUBLIC_BRAND_CODE as string
export const SHOW_ADD_ON_WIDGET = process.env.NEXT_PUBLIC_SHOW_ADD_ON_WIDGET as string
export const PROVE_SERVER_URL = process.env.NEXT_PUBLIC_PROVE_SERVER_URL as string
export const TRUSTEDFORM_SCRIPT_URL = process.env.NEXT_PUBLIC_TRUSTEDFORM_SCRIPT_URL as string
export const LOGIN_SERVER_URL = process.env.NEXT_PUBLIC_LOGIN_SERVER_URL as string
export const NEW_LOGIN_URL = ((process.env.NEXT_PUBLIC_SECURED_DOMAIN_URL as string) +
  (process.env.NEW_LOGIN_URLPATH as string)) as string
export const ENABLE_IDENTITY_SERVER = process.env.NEXT_PUBLIC_ALLOW_IDENTITYSERVER_LOGIN as string
export const IDENTITY_SERVER_URL = process.env.NEXT_PUBLIC_IDENTITY_SERVER_URL as string
export const RETRY_DELAY_MS = Number(process.env.RETRY_DELAY_MS ?? 1000)
export const RETRY_ATTEMPTS_COUNT = Number(process.env.RETRY_ATTEMPTS_COUNT ?? 3)
