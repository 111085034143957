import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkAPI } from '../types'
import useIDIQServices from '../idiqServices'
import { apm } from '@elastic/apm-rum'

interface Args {
  email: string
}

interface ReturnVal {
  success: boolean
  darkWebDataExposureCount: number
}

const getDarkWebDataRequest: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (
  args: Args,
  { rejectWithValue },
) => {
  try {
    const { email } = args
    const idiqServices = useIDIQServices()
    const response = await idiqServices.getDarkWebData(email)

    return response as ReturnVal
  } catch (error) {
    apm.captureError(new Error(error as string))

    return rejectWithValue({})
  }
}

export const getDarkWebData = createAsyncThunk<ReturnVal, Args, ThunkAPI>('user/getDarkWebData', getDarkWebDataRequest)
