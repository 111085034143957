import React, { FunctionComponent } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@/theme'
import Dialog from '@/components/Dialog'
import { H2 } from '@/components/Headings'
import P from '@/components/P'
import IconProgress from './IconProgress'
import IconSuccess from './IconSuccess'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
    paper: {
      textAlign: 'center',
      padding: '70px 0 90px',
      margin: '24px',
      borderRadius: '12px',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(12),
      },
    },
    progress: {
      marginBottom: theme.spacing(3),
    },
    heading: {
      fontWeight: 800,
      letterSpacing: '1px',
      marginBottom: theme.spacing(1),
      textTransform: 'uppercase',
    },
  }),
)

const StatusMap = {
  progress: IconProgress,
  success: IconSuccess,
}

type Props = {
  status: 'progress' | 'success'
  title: string
  description: string
}

const LoadingIndicator: FunctionComponent<Props> = ({ status, title, description }: Props) => {
  const classes = useStyles()
  const Status = StatusMap[status]

  return (
    <Dialog open={true} classes={{ paper: classes.paper }}>
      <Status variant={'modal'} />
      <H2 className={classes.heading}>{title}</H2>
      <P variant="body1">{description}</P>
    </Dialog>
  )
}

export default LoadingIndicator
