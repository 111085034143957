import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { TrackingTriggerMapping } from '@/constants'
import { RootState, ThunkAPI } from '../types'
import { selectEnrollmentState, selectTrackingState } from '@/state/selectors'
// import useIDIQServices from '@/state/idiqServices'

interface Args {
  membershipId?: string
}

const trackUpdateRequest: AsyncThunkPayloadCreator<void, Args, ThunkAPI> = async (
  args: Args,
  { getState, rejectWithValue },
) => {
  const state = getState() as RootState
  const tracking = selectTrackingState(state)
  const enrollment = selectEnrollmentState(state)
  const membershipId = enrollment.enrolledMember?.membershipNumber || args.membershipId
  // const idiqServices = useIDIQServices()

  /*
  if (!tracking.query?.queryObject?.transactionid && !tracking.query?.queryObject?.transaction_id) {
    return
  }
*/
  try {
    const { trigger } = tracking
    if (trigger === TrackingTriggerMapping.Account || trigger === TrackingTriggerMapping.Identity) {
      // idiqServices.updateTrackedMember({
      //   trackingId: tracking.trackingId,
      //   membershipNo: enrollment.enrolledMember?.membershipNumber,
      // })

      const requestUrl = `${process.env.NEXT_PUBLIC_IDIQ_API_GATEWAY_URL}/postbackpixel/updatePostback?key=${process.env.NEXT_PUBLIC_IDIQ_SERVICES_KEY}`
      fetch(requestUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          trackingId: tracking.trackingId,
          membershipNo: membershipId || '',
        }), // body data type must match "Content-Type" header
      })
    }
  } catch (error) {
    return rejectWithValue({})
  }
}

export const trackUpdate = createAsyncThunk<void, Args, ThunkAPI>('tracking/trackUpdate', trackUpdateRequest)
