import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { Plan, ThunkAPI, TrackingInfo } from '../types'
import useIDIQServices from '../idiqServices'
import { getDefaultPlan } from './getDefaultPlan'
import { loadTrigger } from '@/state/tracking'
import { apm } from '@elastic/apm-rum'
import router from 'next/router'
import { Routes } from '@/constants'

interface Args {
  planCode: string
  effortCode: string
  groupingCode?: string
}

interface ReturnVal {
  details: Plan
  effortCode: string
  planCode: string
  trackingInfo: TrackingInfo
}

const getPlanRequest: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (
  args: Args,
  { dispatch, rejectWithValue },
) => {
  try {
    const { planCode, effortCode, groupingCode = '' } = args
    const idiqServices = useIDIQServices()
    let response = await idiqServices.getPlan(planCode, { groupingCode, offerCode: effortCode })

    if (new Date(response.data.effortEndDate) < new Date()) {
      if (response.data.redirectEffort) {
        response = await idiqServices.getPlan(planCode, { groupingCode, offerCode: response.data.redirectEffort })
      } else {
        router.push(Routes.EffortError, Routes.EffortError)
      }
    }

    if (response.data.error) {
      apm.captureError(response.data.error.reason)
      dispatch(getDefaultPlan())

      return rejectWithValue({})
    }

    dispatch(
      loadTrigger({
        trigger: response.data.trackingInfo?.trackingTrigger,
        trackUrl: response.data.trackingInfo?.trackingUrls.TrackingUrl,
      }),
    )

    return {
      details: response.data,
      effortCode,
    } as ReturnVal
  } catch (error) {
    apm.captureError(new Error(error as string))
    dispatch(getDefaultPlan())

    return rejectWithValue({})
  }
}

export const getPlan = createAsyncThunk<ReturnVal, Args, ThunkAPI>('user/getPlan', getPlanRequest)
