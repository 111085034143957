import * as Yup from 'yup'
import creditCardType from 'credit-card-type'
import { format } from 'date-fns'
import { DateFormat, ValidationErrors, WebsiteCode } from '@/constants'
import checkPassword from './checkPassword'
import { differenceInYears, parse } from 'date-fns'
import useIDIQServices from '../../state/idiqServices'
import debounce from 'lodash/debounce'

let validatedUserNames: { [key: string]: boolean } = {}

const validateEmail = async function (email?: string): Promise<boolean> {
  const emailSchema = Yup.string().email()
  const isValid = emailSchema.isValidSync(email)
  if (!email || !isValid) {
    validatedUserNames = {}

    return true
  }
  if (validatedUserNames[email] == undefined) {
    const idiqServices = useIDIQServices()
    try {
      const response = await idiqServices.isUsernameAvailable({ userName: email, websiteCode: WebsiteCode })
      validatedUserNames[email] = response && response.data === 'true'

      return validatedUserNames[email]
    } catch (error) {
      return false
    }
  }

  return validatedUserNames[email]
}

const validateEmailInternal = async function (email: string, resolve: (value: boolean) => void) {
  const result = await validateEmail(email)
  resolve(result)
}

const validateEmailDebounced = debounce(validateEmailInternal, 500) || false

const validateFamilyEmailInternal = async function (email: string, resolve: (value: boolean) => void) {
  const result = await validateEmail(email)
  resolve(result)
}

const validateFamilyEmailDebounced = debounce(validateFamilyEmailInternal, 500) || false

export const isValidName = Yup.string()
  .trim()
  .matches(/^[A-Z-'/\s]+$/gi, ValidationErrors.Name)
export const isValidMiddleInitial = Yup.string()
  .max(1)
  .matches(/[A-Za-z]/g, ValidationErrors.MiddleInitial)

export const isValidEmail = Yup.string()
  .email(ValidationErrors.Email)
  .test(
    'duplicate-username-check',
    ValidationErrors.EmailDuplicate,
    (email) => new Promise((resolve) => validateEmailDebounced(email || '', resolve)),
  )

export const isValidFamilyEmail = Yup.string()
  .email(ValidationErrors.Email)
  .test('compare-email-check', ValidationErrors.FamilyEmailSameAsEmail, function emailCompare(value?: string) {
    return !(this.parent.email && value && this.parent.email === value)
  })
  .test(
    'duplicate-username-check',
    ValidationErrors.EmailDuplicate,
    (email) => new Promise((resolve) => validateFamilyEmailDebounced(email || '', resolve)),
  )

export const isValidFamilyEmailSync = Yup.string()
  .email(ValidationErrors.Email)
  .test('compare-email-check', ValidationErrors.FamilyEmailSameAsEmail, function emailCompare(value?: string) {
    return !(this.parent.email && value && this.parent.email === value)
  })
  .test('duplicate-username-check', ValidationErrors.EmailDuplicate, async (email?: string) => validateEmail(email))

export const isNotMatchInvitationEmail = isValidEmail.test(
  'duplicate-username-check-and-check-ignore',
  ValidationErrors.EmailsShouldNotMatch,
  function emailDoesNotMatch(value?: any) {
    // eslint-disable-line
    return !this.parent.invitationEmail || !value || (value && this.parent.invitationEmail !== value)
  },
)
export const isValidEmailSync = Yup.string()
  .email(ValidationErrors.Email)
  .test('duplicate-username-check', ValidationErrors.EmailDuplicate, async (email?: string) => validateEmail(email))

export const isValidEmailConfirmation = Yup.string()
  .email(ValidationErrors.Email)
  .test('matches-password', ValidationErrors.EmailConfirmation, function emailMatch(value?: any) {
    // eslint-disable-line
    return value && this.parent.email === value
  })

export const isOptionalEmailValidAndDoesNotMatch = Yup.string()
  .email(ValidationErrors.Email)
  .test('emails-dont-match', ValidationErrors.EmailsShouldNotMatch, function emailMatch(value?: any) {
    // eslint-disable-line
    return !value || (value && this.parent.email !== value)
  })

export const isValidPassword = Yup.string().test('password-validation', ValidationErrors.Password, checkPassword)
export const isValidPhone = Yup.string()
  .transform((str: string) => str.replace(/[^\d]/g, ''))
  .matches(/^\d{10}$/, ValidationErrors.Phone)
export const isValidStreetAddress = Yup.string()
  .trim()
  .matches(/^[a-z\s\d-,.'/#]+$/gi, ValidationErrors.StreetAddress)
export const isValidCity = Yup.string()
  .trim()
  .matches(/^[a-z\s'/-]+$/gi, ValidationErrors.City)
export const isValidZip = Yup.string()
  .min(5, ValidationErrors.ZipLength)
  .max(5, ValidationErrors.ZipLength)
  .matches(/^[\d]+$/g, ValidationErrors.Zip)

export const isValidState = Yup.string().matches(
  /^(AL)|(AA)|(AE)|(AK)|(AP)|(AZ)|(AR)|(CA)|(CO)|(CT)|(DC)|(DE)|(FL)|(GA)|(GU)|(HI)|(ID)|(IL)|(IN)|(IA)|(KS)|(KY)|(LA)|(ME)|(MD)|(MA)|(MI)|(MN)|(MS)|(MO)|(MT)|(NE)|(NV)|(NH)|(NJ)|(NM)|(NY)|(NC)|(ND)|(OH)|(OK)|(OR)|(PA)|(PR)|(RI)|(SC)|(SD)|(TN)|(TX)|(UT)|(VI)|(VT)|(VA)|(WA)|(WV)|(WI)|(WY)$/,
  ValidationErrors.State,
)

export const isValidCCV = Yup.string()
  .max(4, ValidationErrors.CCVLength)
  .min(3, ValidationErrors.CCVLength)
  .matches(/^\d+$/g, ValidationErrors.CCV)

export const isValidCreditCardNumber = Yup.string().test(
  'ccnumber-test',
  ValidationErrors.CreditCardNumber,
  (ccnumber?: string) => {
    if (!ccnumber) {
      return false
    }

    const card = creditCardType(ccnumber)[0]

    return (
      Boolean(card) &&
      Boolean(card.niceType) &&
      Boolean(card.lengths) &&
      Boolean(card.lengths?.includes(ccnumber.length)) &&
      /^\d+$/gi.test(ccnumber)
    )
  },
)

const isMonthValid = (month: string): boolean => {
  if (month) {
    const monthRegex = new RegExp('^[0-9][0-9]$')
    const is2digitMonth = monthRegex.test(month)
    if (is2digitMonth) {
      const currentMonth = parseInt(format(new Date(), 'MM'))

      return !(parseInt(month) < currentMonth)
    }
  }

  return true
}

const isYearValid = (value: string, testFuture?: boolean): boolean => {
  if (value) {
    const userValueExpiration = value
    const month = userValueExpiration.split('/')[0]
    const year = userValueExpiration.split('/')[1]
    const yearRegex = new RegExp('^[0-9][0-9]$')
    const is2DigitYear = yearRegex.test(year)
    if (is2DigitYear) {
      const currentYear = parseInt(format(new Date(), 'yy'))
      if (testFuture) {
        return !(parseInt(year) > currentYear + 9)
      } else {
        // if the entered year is the current year, we need to validate the month is not in the past
        return parseInt(year) == currentYear ? isMonthValid(month) : !(parseInt(year) < currentYear)
      }
    }
  }

  return true
}

export const isValidExpirationDate = Yup.string()
  .matches(/(0[1-9]|1[0-2])\/([0-9]{2})/, ValidationErrors.ExpirationDateFormat)
  .test('YearInPast', ValidationErrors.ExpirationDateCannotBeInPast, (value) => {
    return isYearValid(value as string, false)
  })
  .test(
    'YearTooFarInFuture',
    ValidationErrors.ExpirationTooFarInFuture.replace('{0}', `${parseInt(new Date().getFullYear().toString()) + 9}`),
    (value) => {
      return isYearValid(value as string, true)
    },
  )

export const validateUnless = (conditionKey: string | string[], validation: Yup.StringSchema): Yup.StringSchema =>
  Yup.string().when(conditionKey, {
    is: false,
    then: validation,
  })

export const validateWhen = (
  conditionKey: string | string[],
  validation: Yup.StringSchema | Yup.BooleanSchema,
): Yup.StringSchema | Yup.BooleanSchema =>
  Yup.string().when(conditionKey, {
    is: true,
    then: validation,
  })

export const isValidSSN = Yup.string()
  .transform((str: string) => str.replace(/[^\d]/g, ''))
  .matches(/\d+/, ValidationErrors.SSN)
  .min(9, ValidationErrors.MinLength)
  .max(9, ValidationErrors.MaxLength)

export const isValidLastFourSSN = Yup.string()
  .transform((str: string) => str.replace(/[^\d]/g, ''))
  .matches(/\d+/, ValidationErrors.SSN)
  .min(4, ValidationErrors.MinLength)
  .max(4, ValidationErrors.MaxLength)

export const isValidDOB = Yup.string()
  .matches(/(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}/, ValidationErrors.DateOfBirth)
  .test('is-valid-date', ValidationErrors.Required, (value) => /\d\d\/\d\d\/\d\d\d\d/.test(String(value)))
  .test('is-old-enough', ValidationErrors.MinAge, (value) => {
    const dob = parse(value as string, DateFormat, new Date())
    const today = new Date()
    const diff = differenceInYears(today, dob)

    return diff >= 18
  })

export const isValidConfirmation = (shouldMatchKey: string, message: string): Yup.StringSchema =>
  Yup.string().test(`${shouldMatchKey}-confirmation`, message, function (value) {
    return this.parent[shouldMatchKey] === value
  })

export const isValidConfirmationTransformed =
  (transform: (str: string) => string) =>
  (shouldMatchKey: string, message: string): Yup.StringSchema =>
    Yup.string().test(`${shouldMatchKey}-confirmation`, message, function (value) {
      return transform(this.parent[shouldMatchKey]) === transform(value as string)
    })
export const isOptionalEmailValid = Yup.string().email(ValidationErrors.Email)
