const checkPassword = (password: string | undefined): boolean => {
  if (!password) {
    return true
  }

  // at least one capital letter, one lowercase letter, one number, and one symbol
  // and password length is at least 8
  if (/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/g.test(password) && password.length >= 8) {
    return true
  }

  return false
}

export default checkPassword
