import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAddOnsState, selectPlanState } from '@/state/selectors'
import useQueryString from './useQueryString'
import { parseQueryString } from '@/utils'
import { setAddOns, setInterstitialAddOns } from '@/state/addOns'
import { getPostEnrollmentAddOnsData } from '@/state/actions'
const useAddOns = () => {
  const dispatch = useDispatch()
  const { queryObject = {} } = useQueryString()
  const { addOnCodes, interstitialAddOnCodes } = parseQueryString(queryObject)
  const { effortCode, details: { benefits, addOnInterstitialPagePosition, partnerCode, planCode } = {} } =
    useSelector(selectPlanState)
  const { isInitialized } = useSelector(selectAddOnsState)

  useEffect(() => {
    if (isInitialized || !benefits) {
      return
    }
    if (!!addOnCodes || !!interstitialAddOnCodes) {
      dispatch(setAddOns({ benefits, addOnCodes, interstitialAddOnCodes }))
    }
    if (addOnInterstitialPagePosition && addOnInterstitialPagePosition.includes('BEFORE_ENROLLMENT')) {
      dispatch(setInterstitialAddOns({ benefits, interstitialAddOnCodes }))
    }
    if (addOnInterstitialPagePosition && addOnInterstitialPagePosition.includes('ENROLLMENT_UPGRADE')) {
      if (partnerCode && effortCode && planCode) {
        dispatch(getPostEnrollmentAddOnsData({ marketingProgramCode: partnerCode, offerCode: effortCode, planCode }))
      }
    }
  }, [isInitialized, addOnCodes, benefits])
}

export default useAddOns
