import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { ErrorCodes, ErrorMessagesMap, SessionStore } from '@/constants'
import { EnrollMemberData } from '@idiq-ppd/idiq-client/src/types/EnrollMemberData'
import { trimAllStringValues } from '@/utils'
import { IDIQError } from '@/state/idiqServices'
import { AccountInformation, EnrollmentStep, IdentityInformation, BillingInformation } from './types'
import { enrollMember } from './actions'
import { Coupon } from '@/state/types'

export interface EnrollmentState {
  step: EnrollmentStep
  account: AccountInformation
  identity: IdentityInformation
  billing: BillingInformation
  isFetching: boolean
  error: null | string
  errorCode?: null | string
  enrolledMember?: EnrollMemberData & { cuid?: string; password?: string }
  postEnrollmentAddonShowed: boolean
  isProveWorkflow: boolean
  formValid: boolean
}

const initialState: EnrollmentState = {
  isFetching: false,
  error: null,
  step: EnrollmentStep.AccountInfo,
  account: {
    activationCode: undefined,
    membershipInvitationKey: undefined,
    trustedFormCertId: '',
    currentStreet: '',
    currentCity: '',
    currentState: '',
    currentZip: '',
    isCurrentAddress: true,
    email: '',
    firstName: '',
    middleInitial: '',
    lastName: '',
    password: '',
    previousStreet: '',
    previousCity: '',
    previousState: '',
    previousZip: '',
    familyInvitationEmail: '',
  },
  identity: {
    ssn: '',
    lastFourSSN: '',
    dateOfBirth: '',
    subscribeSMS: false,
    phone: '',
    proveSessionId: '',
    isProveBypass: false,
    trustScore: 0,
  },
  billing: {
    ccv: '',
    city: '',
    creditCardNumber: '',
    expiration: '',
    isBillingHomeAddress: true,
    street: '',
    state: '',
    zip: '',
    appliedCoupon: null,
    couponCode: '',
    phone: '',
    subscribeSMS: false,
    kountSessionId: '',
  },
  postEnrollmentAddonShowed: false,
  isProveWorkflow: false,
  formValid: false,
}

const enrollment: Slice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    cacheAccountInfo: (
      state,
      action: PayloadAction<
        AccountInformation & { isValid: boolean; isSaveOnly?: boolean; isPlanAuthRequired?: boolean }
      >,
    ) => {
      const newAccountInfo = trimAllStringValues(action.payload as unknown as Record<string, string>)
      state.account = { ...state.account, ...newAccountInfo }
      sessionStorage?.setItem(SessionStore.Account, JSON.stringify(state.account))

      if (action.payload.isSaveOnly) {
        return
      }

      state.error = null
      state.errorCode = null

      if (action.payload.isValid) {
        state.step = action.payload.isPlanAuthRequired ? EnrollmentStep.IdentityInfo : EnrollmentStep.BillingInfo

        sessionStorage?.setItem(SessionStore.Step, String(state.step))
      } else {
        state.step = EnrollmentStep.AccountInfo
      }
    },
    cacheIdentityInfo: (
      state,
      action: PayloadAction<
        IdentityInformation & { isNoAccount: boolean; isProvePrefill?: boolean; isSaveOnly?: boolean }
      >,
    ) => {
      const newIdentityInfo = trimAllStringValues(action.payload as unknown as Record<string, string>)
      state.identity = { ...state.identity, ...newIdentityInfo }
      if (!action.payload.isSaveOnly) {
        const step = action.payload.isNoAccount ? EnrollmentStep.IdentityInfo : EnrollmentStep.BillingInfo
        state.step = action.payload.isProvePrefill && !action.payload.password ? EnrollmentStep.IdentityInfo : step
        sessionStorage?.setItem(SessionStore.Step, String(step))

        state.error = null
        state.errorCode = null
      }

      if (action.payload.email) {
        state.account.email = action.payload.email
        sessionStorage?.setItem(SessionStore.Account, JSON.stringify(state.account))
      }

      if (action.payload.password) {
        state.account.password = action.payload.password
        sessionStorage?.setItem(SessionStore.Account, JSON.stringify(state.account))
      }

      sessionStorage?.setItem(SessionStore.Identity, JSON.stringify(state.identity))
    },
    cacheBillingInfo: (state, action: PayloadAction<BillingInformation>) => {
      const newBillingInfo = trimAllStringValues(action.payload as unknown as Record<string, string>)
      state.billing = { ...state.billing, ...newBillingInfo }
      state.error = null
      state.errorCode = null
    },
    cacheForBillingStepFromSession: (
      state,
      action: PayloadAction<{ account: AccountInformation; identity: IdentityInformation }>,
    ) => {
      state.account = trimAllStringValues(action.payload.account as unknown as Record<string, string>)

      if (action.payload.identity) {
        state.identity = trimAllStringValues(action.payload.identity as unknown as Record<string, string>)

        if (action.payload.identity?.email) {
          state.account.email = action.payload.identity.email
        }

        if (action.payload.identity?.password) {
          state.account.password = action.payload.identity.password
        }
      }
      state.step = EnrollmentStep.BillingInfo
      state.error = null
      state.errorCode = null
    },
    cachePromoCodeInfo: (state, action: PayloadAction<Coupon>) => {
      state.billing.couponCode = action.payload.couponCode
      state.billing.appliedCoupon = action.payload.couponCode ? action.payload : { isValid: action.payload.isValid }

      if (action.payload?.isValid) {
        state.error = null
        state.errorCode = null
      }
    },
    returnToBilling: (state) => {
      state.step = EnrollmentStep.BillingInfo
      state.error = null
      state.errorCode = null
      state.billing = {
        ccv: '',
        city: '',
        creditCardNumber: '',
        expiration: '',
        isBillingHomeAddress: true,
        street: '',
        state: '',
        zip: '',
        couponCode: '',
        phone: '',
        subscribeSMS: false,
        kountSessionId: '',
      }
    },
    returnToIdentity: (
      state,
      action: PayloadAction<{ dob: string; phoneNumber: string; isSMSSubscribed: boolean }>,
    ) => {
      state.step = EnrollmentStep.IdentityInfo
      state.error = null
      state.errorCode = null
      state.identity = {
        ssn: '',
        lastFourSSN: '',
        dateOfBirth: action.payload.dob,
        phone: action.payload.phoneNumber,
        subscribeSMS: action.payload.isSMSSubscribed,
      }
    },
    cacheSSN: (state, action: PayloadAction<{ ssn: string }>) => {
      state.identity.ssn = action.payload.ssn
      state.error = null
      state.errorCode = null
    },
    cacheEnrollMemberData: (
      state,
      action: PayloadAction<{
        offerCode: string
        planCode: string
        membershipNumber: string
        cuid: string
      }>,
    ) => {
      state.enrolledMember = { ...action.payload }
      state.error = null
      state.errorCode = null
    },
    setPostEnrollmentAddonShowed: (state) => {
      state.postEnrollmentAddonShowed = true
    },
    setProveWorkflow: (state, action: PayloadAction<boolean>) => {
      state.isProveWorkflow = action.payload
      sessionStorage?.setItem(SessionStore.IsProveWorkflow, String(state.isProveWorkflow))
    },
    setFormValid: (state, action: PayloadAction<boolean>) => {
      state.formValid = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(enrollMember.pending, (state) => {
        state.isFetching = true
      })
      .addCase(enrollMember.rejected, (state, action) => {
        const _payload = action.payload as IDIQError
        state.isFetching = false
        state.error = _payload.message || ErrorMessagesMap[ErrorCodes.InternalError]
        state.errorCode = _payload.code

        switch (_payload.code) {
          case ErrorCodes.BlocklistBySSN:
          case ErrorCodes.BlocklistByIPAddress:
          case ErrorCodes.BlocklistByCC:
            state.step = EnrollmentStep.AccountInfo
            break
          case ErrorCodes.InvalidDateOfBirth:
          case ErrorCodes.InvalidSSN:
            state.step = EnrollmentStep.IdentityInfo
            break
          case ErrorCodes.InvalidCoupon:
            state.step = EnrollmentStep.BillingInfo
            state.billing.couponCode = ''
            state.billing.appliedCoupon = null
            break
          default:
            break
        }
      })
      .addCase(enrollMember.fulfilled, (state, action) => {
        state.isFetching = false
        state.error = null
        state.enrolledMember = action.payload as unknown as EnrollMemberData
        state.enrolledMember.password = state.account.password
        state.enrolledMember.email = state.account.email

        sessionStorage?.setItem(
          SessionStore.EnrollMember,
          JSON.stringify({
            membershipNumber: state.enrolledMember.membershipNumber,
            cuid: state.enrolledMember.cuid,
            email: state.enrolledMember.email,
            password: state.account.password,
            ssn: state.identity.ssn,
          }),
        )
      })
  },
})

export const {
  cacheAccountInfo,
  cacheIdentityInfo,
  cacheBillingInfo,
  cacheForBillingStepFromSession,
  returnToBilling,
  returnToIdentity,
  cacheSSN,
  cacheEnrollMemberData,
  cachePromoCodeInfo,
  setPostEnrollmentAddonShowed,
  setProveWorkflow,
  setFormValid,
} = enrollment.actions
export default enrollment.reducer
