import React, { FunctionComponent, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import { EnrollmentFormID, Headings, Images, Labels, TRUSTEDFORM_SCRIPT_URL } from '@/constants'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import cx from 'classnames'
import { FormControlLabel, InputAdornment, IconButton, Tooltip } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { useFormik } from 'formik'
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons'
import { limitZipCodeLength } from '../../utils/fieldLengthValidator'
import { useStyles } from './styles'
import { validationSchema } from './validationSchema'
import { InitialValues, initialValues } from './initialValues'
import ErrorCard from '@/components/ErrorCard'
import { useSelector } from 'react-redux'
import {
  selectEnrollmentState,
  selectPlanState,
  selectInvitationPlanState,
  selectActivationState,
} from '@/state/selectors'
import useGlobalAccountState from '../../hooks/useGlobalAccountState'
import AddressStateSelector from '../AddressStateSelector'
import { scrollToError } from '@/utils'
import dynamic from 'next/dynamic'
import Terms from '../Terms'
import { H2 } from '../Headings'

const DiscountPrompt = dynamic(() => import('../DiscountPrompt'))
const ValidInputIcon = dynamic(() => import('../ValidInputIcon'))

interface Props {
  onSubmit: (fields: InitialValues) => void
}

const FormAccountInfo: FunctionComponent<Props> = ({ onSubmit }: Props) => {
  const classes = useStyles()
  const { error } = useSelector(selectEnrollmentState)
  const { details: planDetails } = useSelector(selectPlanState)
  const invitationPlanState = useSelector(selectInvitationPlanState)
  const { details: activationDetails } = useSelector(selectActivationState)
  const [showPassword, setShowPassword] = useState(false)
  const isTrustedFormEnabled = planDetails?.trustedFormEnabled

  const shouldShowCPromptForDiscountCode =
    planDetails?.isPromptForDiscountCode &&
    !activationDetails &&
    (!invitationPlanState.hasKey || (invitationPlanState.details && !invitationPlanState.details?.couponCode))

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    setTouched,
    isSubmitting,
    isValidating,
  } = useFormik<InitialValues>({
    initialValues,
    onSubmit,
    validationSchema,
  })

  useEffect(() => {
    if (invitationPlanState.details?.toEmail) {
      setFieldValue('email', invitationPlanState.details.toEmail)
    }
  }, [invitationPlanState])

  useGlobalAccountState({ setValues, setTouched })

  scrollToError({ errors, isSubmitting, isValidating, isShowGeneralError: !!error })

  if (isTrustedFormEnabled) {
    useEffect(() => {
      const script = document.createElement('script')
      script.src = TRUSTEDFORM_SCRIPT_URL + new Date().getTime() + Math.random()
      script.async = true
      script.type = 'text/javascript'
      document.body.appendChild(script)
    }, [])
  }

  return (
    <form onSubmit={handleSubmit} id={EnrollmentFormID}>
      <Card className={classes.root} variant="outlined">
        {error && <ErrorCard>{error}</ErrorCard>}
        <H2 className={classes.title}>{Headings.AccountInformation}</H2>
        <Grid container spacing={2}>
          <Grid item className={classes.firstName}>
            <TextField
              variant="outlined"
              margin="normal"
              disabled={values.isFirstNameReadOnly}
              fullWidth
              id="firstName"
              label={Labels.FirstName}
              name="firstName"
              autoComplete="given-name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              error={touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
              classes={{
                root: values.firstName && !errors.firstName && !values.isFirstNameReadOnly ? classes.validInput : '',
              }}
              InputProps={{
                endAdornment: values.firstName && !errors.firstName && !values.isFirstNameReadOnly && (
                  <ValidInputIcon />
                ),
                classes: { notchedOutline: classes.notchedOutline },
              }}
            />
          </Grid>
          <Grid item className={classes.middleInitial}>
            <TextField
              variant="outlined"
              margin="normal"
              id="middleInitial"
              label={Labels.MiddleInitial}
              name="middleInitial"
              inputProps={{ maxLength: 1 }}
              autoComplete="additional-name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.middleInitial}
              error={touched.middleInitial && !!errors.middleInitial}
              helperText={touched.middleInitial && errors.middleInitial}
              classes={{
                root: values.middleInitial && !errors.middleInitial ? classes.validInput : '',
              }}
              InputProps={{
                endAdornment: values.middleInitial && !errors.middleInitial && <ValidInputIcon />,
                classes: { notchedOutline: classes.notchedOutline },
              }}
              onKeyDown={(e) => !/[a-zA-Z]/.test(e.key) && e.preventDefault()}
            />
          </Grid>

          <Grid item className={classes.lastName} xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              disabled={values.isLastNameReadOnly}
              fullWidth
              name="lastName"
              label={Labels.LastName}
              id="lastName"
              autoComplete="family-name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              error={touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
              classes={{
                root: values.lastName && !errors.lastName && !values.isLastNameReadOnly ? classes.validInput : '',
              }}
              InputProps={{
                endAdornment: values.lastName && !errors.lastName && !values.isLastNameReadOnly && <ValidInputIcon />,
                classes: { notchedOutline: classes.notchedOutline },
              }}
            />
          </Grid>
          <Grid item className={classes.email} xs={12} lg={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label={Labels.Email}
              name="email"
              autoComplete="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={(touched.email || !!values.email) && !!errors.email}
              helperText={(touched.email || !!values.email) && errors.email}
              classes={{
                root: (touched.email || !!values.email) && !errors.email ? classes.validInput : '',
              }}
              InputProps={{
                endAdornment: (touched.email || !!values.email) && !errors.email && <ValidInputIcon />,
                classes: { notchedOutline: classes.notchedOutline },
              }}
            />
          </Grid>
          <Grid item className={classes.password} xs={12} lg={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label={Labels.Password}
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="new-password"
              className={'password-like'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              classes={{
                root: values.password && !errors.password ? classes.validInput : '',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {values.password && !errors.password && <img src={Images.GreenCheckmarkIcon} alt="" />}
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      className={classes.eyeIcon}
                    >
                      {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: { notchedOutline: classes.notchedOutline },
              }}
            />
          </Grid>
        </Grid>
        <H2 className={`${classes.title} ${classes.subTitle}`}>{Headings.CurrentAddress}</H2>
        <Grid container spacing={2}>
          <Grid item className={classes.street} xs={12} lg={6}>
            <TextField
              variant="outlined"
              margin="normal"
              disabled={values.isCurrentStreetReadOnly}
              fullWidth
              name={`currentStreet`}
              label={Labels.Street}
              id={`currentStreet`}
              autoComplete="street-address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentStreet}
              error={touched.currentStreet && !!errors.currentStreet}
              helperText={touched.currentStreet && errors.currentStreet}
              classes={{
                root:
                  values.currentStreet && !errors.currentStreet && !values.isCurrentStreetReadOnly
                    ? classes.validInput
                    : '',
              }}
              InputProps={{
                endAdornment: values.currentStreet && !errors.currentStreet && !values.isCurrentStreetReadOnly && (
                  <ValidInputIcon />
                ),
                classes: { notchedOutline: classes.notchedOutline },
              }}
            />
          </Grid>
          <Grid item className={classes.city} xs={12} lg={6}>
            <TextField
              variant="outlined"
              margin="normal"
              disabled={values.isCurrentCityReadOnly}
              fullWidth
              name={`currentCity`}
              label={Labels.City}
              id={`currentCity`}
              autoComplete="address-level2"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentCity}
              error={touched.currentCity && !!errors.currentCity}
              helperText={touched.currentCity && errors.currentCity}
              classes={{
                root:
                  values.currentCity && !errors.currentCity && !values.isCurrentCityReadOnly ? classes.validInput : '',
              }}
              InputProps={{
                endAdornment: values.currentCity && !errors.currentCity && !values.isCurrentCityReadOnly && (
                  <ValidInputIcon />
                ),
                classes: { notchedOutline: classes.notchedOutline },
              }}
            />
          </Grid>
          <Grid item className={classes.state} xs={6}>
            <FormControl
              className={classes.stateFormControl}
              margin="normal"
              disabled={values.isCurrentStateReadOnly}
              classes={{
                root:
                  values.currentState && !errors.currentState && !values.isCurrentStateReadOnly
                    ? classes.validInput
                    : '',
              }}
            >
              <InputLabel className={classes.stateSelection} htmlFor="ddlCurrentState">
                State*
              </InputLabel>
              <Select
                native
                variant="outlined"
                className={classes.select}
                disabled={values.isCurrentStateReadOnly}
                fullWidth
                name="currentState"
                label={Labels.State}
                id="currentState"
                autoComplete="address-level1"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.currentState}
                error={touched.currentState && !!errors.currentState}
                endAdornment={
                  values.currentState &&
                  !errors.currentState &&
                  !values.isCurrentStateReadOnly && <ValidInputIcon className={classes.stateAdornment} />
                }
              >
                <AddressStateSelector />
              </Select>
              <FormHelperText error={touched.currentState && !!errors.currentState}>
                {touched.currentState && errors.currentState}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item className={classes.zipCode} xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              type="text"
              disabled={values.isCurrentZipReadOnly}
              fullWidth
              name={`currentZip`}
              label={Labels.Zip}
              id={`currentZip`}
              autoComplete="postal-code"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentZip}
              error={touched.currentZip && !!errors.currentZip}
              helperText={touched.currentZip && errors.currentZip}
              classes={{
                root: values.currentZip && !errors.currentZip && !values.isCurrentZipReadOnly ? classes.validInput : '',
              }}
              InputProps={{
                endAdornment: values.currentZip && !errors.currentZip && !values.isCurrentZipReadOnly && (
                  <ValidInputIcon />
                ),
                classes: { notchedOutline: classes.notchedOutline },
              }}
              inputProps={{
                inputMode: 'numeric',
              }}
              onInput={limitZipCodeLength}
            />
          </Grid>
        </Grid>
        <div className={classes.checkbox}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id="isCurrentAddress"
                checked={values.isCurrentAddress}
                onChange={handleChange}
              />
            }
            label="I have been at my current address for six months or more."
            classes={{
              root: classes.checkboxFormControlLabel,
              label: classes.checkboxFormControlLabelText,
            }}
          />
        </div>
        {!values.isCurrentAddress && (
          <div className={classes.previousAddress}>
            <H2 className={classes.title}>{Headings.PreviousAddress}</H2>
            <Grid container spacing={2}>
              <Grid item className={classes.street} xs={12} lg={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name={`previousStreet`}
                  label={Labels.Street}
                  id={`previousStreet`}
                  autoComplete="street-address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.previousStreet}
                  error={touched.previousStreet && !!errors.previousStreet}
                  helperText={touched.previousStreet && errors.previousStreet}
                  classes={{
                    root: values.previousStreet && !errors.previousStreet ? classes.validInput : '',
                  }}
                  InputProps={{
                    endAdornment: values.previousStreet && !errors.previousStreet && <ValidInputIcon />,
                    classes: { notchedOutline: classes.notchedOutline },
                  }}
                />
              </Grid>
              <Grid item className={classes.city} xs={12} lg={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name={`previousCity`}
                  label={Labels.City}
                  id={`previousCity`}
                  autoComplete="address-level2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.previousCity}
                  error={touched.previousCity && !!errors.previousCity}
                  helperText={touched.previousCity && errors.previousCity}
                  classes={{
                    root: values.previousCity && !errors.previousCity ? classes.validInput : '',
                  }}
                  InputProps={{
                    endAdornment: values.previousCity && !errors.previousCity && <ValidInputIcon />,
                    classes: { notchedOutline: classes.notchedOutline },
                  }}
                />
              </Grid>
              <Grid item className={classes.state} xs={6}>
                <FormControl
                  className={classes.stateFormControl}
                  margin="normal"
                  classes={{
                    root: values.previousState && !errors.previousState ? classes.validInput : '',
                  }}
                >
                  <InputLabel className={classes.stateSelection} htmlFor="previousState">
                    State*
                  </InputLabel>
                  <Select
                    native
                    variant="outlined"
                    className={classes.select}
                    fullWidth
                    name={`previousState`}
                    label={Labels.State}
                    id={`previousState`}
                    autoComplete="address-level1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.previousState}
                    error={touched.previousState && !!errors.previousState}
                    endAdornment={
                      values.previousState &&
                      !errors.previousState && <ValidInputIcon className={classes.stateAdornment} />
                    }
                  >
                    <AddressStateSelector />
                  </Select>
                </FormControl>
                <FormHelperText error={touched.previousState && !!errors.previousState}>
                  {touched.previousState && errors.previousState}
                </FormHelperText>
              </Grid>
              <Grid item className={classes.zipCode} xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="text"
                  fullWidth
                  name={`previousZip`}
                  label={Labels.Zip}
                  id={`previousZip`}
                  autoComplete="postal-code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.previousZip}
                  error={touched.previousZip && !!errors.previousZip}
                  helperText={touched.previousZip && errors.previousZip}
                  classes={{
                    root: values.previousZip && !errors.previousZip ? classes.validInput : '',
                  }}
                  InputProps={{
                    endAdornment: values.previousZip && !errors.previousZip && <ValidInputIcon />,
                    classes: { notchedOutline: classes.notchedOutline },
                  }}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onInput={limitZipCodeLength}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {planDetails?.isFamilyPlan && (
          <Grid className={classes.familyInvitationEmail}>
            <div className={classes.familyPlanTitleWrapper}>
              <H2 className={cx(classes.title, classes.familyTitle)}>{Headings.FamilyMemberInvitation}</H2>
              <Tooltip
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                placement="top-start"
                title="Your family membership includes one adult family member. Please enter the email below."
              >
                <img width="20" height="20" src={Images.IconHelp} alt="i" />
              </Tooltip>
            </div>
            <TextField
              fullWidth
              placeholder="Email Address for Adult Family Member"
              variant="outlined"
              margin="normal"
              id="familyInvitationEmail"
              label={Labels.FamilyEmail}
              name="familyInvitationEmail"
              autoComplete="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.familyInvitationEmail}
              error={touched.familyInvitationEmail && !!errors.familyInvitationEmail}
              helperText={touched.familyInvitationEmail && errors.familyInvitationEmail}
              classes={{
                root:
                  touched.familyInvitationEmail && values.familyInvitationEmail && !errors.familyInvitationEmail
                    ? classes.validInput
                    : '',
              }}
              InputProps={{
                endAdornment: touched.familyInvitationEmail &&
                  values.familyInvitationEmail &&
                  !errors.familyInvitationEmail && <ValidInputIcon />,
                classes: { notchedOutline: classes.notchedOutline },
              }}
            />
          </Grid>
        )}

        <Terms
          isShowError={touched.isTermsAgree && !!errors.isTermsAgree}
          value={values.isTermsAgree}
          onChange={handleChange}
        />
      </Card>
      {shouldShowCPromptForDiscountCode && <DiscountPrompt />}
    </form>
  )
}

export default FormAccountInfo
