import React, { FunctionComponent } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Provider } from 'react-redux'
import { store } from '@/state'
import { ElasticServerUrl, ElasticServiceName, Title } from '@/constants'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import GtmScript from '@/components/GtmScript'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { init as initApm, AgentConfigOptions } from '@elastic/apm-rum'
import { getTheme } from 'src/themes/base'

const apmOptions: AgentConfigOptions = {
  serviceName: ElasticServiceName,
  serverUrl: ElasticServerUrl,
  serviceVersion: `${process.env.NEXT_PUBLIC_ENV}_${process.env.NEXT_PUBLIC_BUILD_ID}`,
  active: process.env.NEXT_PUBLIC_ENV !== 'DEV',
  environment: process.env.NEXT_PUBLIC_ENV,
  breakdownMetrics: true,
  distributedTracingOrigins: [process.env.NEXT_PUBLIC_IDIQ_API_GATEWAY_URL || ''],
  eventsLimit: 160,
}

initApm(apmOptions)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      maxWidth: '1280px',
      padding: '0 40px',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        margin: '0',
        padding: '0 10px',
      },
    },
    background: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(90deg, rgba(128, 0, 255, 0.03) 0%, rgba(0, 179, 255, 0.03) 100%)',
      filter: 'blur(93px)',
      borderRadius: '33%',
      zIndex: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }),
)

const App: FunctionComponent<AppProps> = (props: AppProps) => {
  const { Component, pageProps } = props
  const classes = useStyles()

  /**
   * Remove the server-side injected CSS.
   */
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles)
    }
  }, [])

  return (
    <Provider store={store}>
      <Head>
        <title>{Title}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="shortcut icon" id="favicon" href={process.env.FAVICON_IMG} />
      </Head>
      <GtmScript />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={getTheme(process.env.THEME_NAME as string)}>
          <CssBaseline />
          {process.env.NEXT_PUBLIC_BRAND_CODE === 'CSIQ' && <div className={classes.background}></div>}
          <Grid container classes={{ root: classes.bodyContainer }}>
            <Component {...pageProps} />
          </Grid>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  )
}

export default App
