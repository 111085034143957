import React, { FunctionComponent } from 'react'
import { Images } from '@/constants'
import { InputAdornment } from '@material-ui/core'

type Props = {
  className?: string
  info?: boolean
}

const ValidInputIcon: FunctionComponent<Props> = ({ className, info = false }: Props) => {
  return (
    <InputAdornment position="end" className={className}>
      <img src={info ? Images.InfoIcon : Images.GreenCheckmarkIcon} alt="" />
    </InputAdornment>
  )
}

export default ValidInputIcon
