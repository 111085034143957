import { apm } from '@elastic/apm-rum'
import router from 'next/router'
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkAPI, PostEnrollmentAddOn } from '../types'
import { Routes } from '@/constants'
import { qs } from '../../utils/queryString'

interface Args {
  marketingProgramCode: string
  offerCode: string
  planCode: string
}

interface ReturnVal {
  details: PostEnrollmentAddOn[]
}

const getPostEnrollmentAddOns: AsyncThunkPayloadCreator<ReturnVal, Args, ThunkAPI> = async (
  { marketingProgramCode, offerCode, planCode }: Args,
  { rejectWithValue },
) => {
  try {
    const query = qs.stringify({
      marketingProgramCode,
      offerCode,
      planCode,
    })
    const requestUrl = `${process.env.NEXT_PUBLIC_API_URL}/effort-service/v1/interstitialAddon?${query}`
    const response = await fetch(requestUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData: PostEnrollmentAddOn = await response.json()

    if (responseData.errors) {
      return {
        details: null,
      } as unknown as ReturnVal
    }

    return {
      details: responseData,
    } as unknown as ReturnVal
  } catch (error) {
    apm.captureError(new Error(error as string))
    router.push(Routes.TechError, Routes.TechError)

    return rejectWithValue({})
  }
}

export const getPostEnrollmentAddOnsData = createAsyncThunk<ReturnVal, Args, ThunkAPI>(
  'user/getPostEnrollmentAddOnsData',
  getPostEnrollmentAddOns,
)
