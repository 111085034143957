export enum SessionStore {
  Account = 'accountInfo',
  Identity = 'identityInfo',
  EnrollMember = 'enrollMemberData',
  Step = 'step',
  IsRecaptchaRequired = 'isRecaptchaRequired',
  TrackingId = 'trackingId',
  Trigger = 'trigger',
  TrackUrl = 'trackUrl',
  FullUrl = 'fullUrl',
  PlanInfo = 'planInfo',
  Query = 'query',
  IsProveWorkflow = 'isProveWorkflow',
  ActivationInfo = 'activationInfo',
  ResidentData = 'residentData',
  QuickviewReport = 'quickviewReport',
  DisableQuickview = 'disableQuickview',
  ProvePrefill = 'ProvePrefillData',
  DarkWebData = 'darkWebData',
}
