import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, ThunkAPI } from '../types'
import useIDIQServices from '../idiqServices'
import { parseQueryStringTrackInfo } from '@/utils'
import { apm } from '@elastic/apm-rum'

interface Args {
  firstName: string
  lastName: string
  email: string
  trustedFormCertId?: string
  shipping: {
    currentStreet: string
    currentCity: string
    currentState: string
    currentZip: string
  }
}

const addEnrollmentLeadRequest: AsyncThunkPayloadCreator<void, Args, ThunkAPI> = async (
  args: Args,
  { rejectWithValue, getState },
) => {
  try {
    const idiqServices = useIDIQServices()
    const state = getState() as RootState
    const { plan, tracking } = state

    apm.setCustomContext({ userAction: 'addEnrollmentLeadRequest' })

    await idiqServices.addEnrollmentLead({
      offerCode: plan.effortCode,
      planCode: plan.details?.planCode,
      source: tracking.fullUrl || '',
      trackInfo: parseQueryStringTrackInfo(tracking.query?.queryString || ''),
      firstName: args.firstName,
      lastName: args.lastName,
      email: args.email,
      trustedFormCertId: args.trustedFormCertId,
      shipping: {
        street: args.shipping.currentStreet,
        city: args.shipping.currentCity,
        state: args.shipping.currentState,
        zip: args.shipping.currentZip,
      },
    })
  } catch (e) {
    const error = e as Error
    apm.captureError(error)

    return rejectWithValue(error?.message)
  }
}

export const addEnrollmentLead = createAsyncThunk<void, Args, ThunkAPI>(
  'user/addEnrollmentLead',
  addEnrollmentLeadRequest,
)
