import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cacheAccountInfo } from '@/state/enrollment'
import { InitialValues } from '@/components/FormAccountInfo/initialValues'
import FormAccountInfo from '@/components/FormAccountInfo'
import { Grid, makeStyles } from '@material-ui/core'
import CartContainer from '@/containers/CartContainer'
import { Routes, TrackingTriggerMapping } from '@/constants'
import useTracker from '../../hooks/useTracker'
import { addEnrollmentLead, enrollMember, getInvitationPlan, getQuickviewReport, getDarkWebData } from '@/state/actions'
import { useRouter } from 'next/router'
import {
  selectPlanState,
  selectActivationState,
  selectEnrollmentState,
  selectInvitationPlanState,
} from '@/state/selectors'
import AccountInfoSkeleton from '@/components/FormAccountInfo/skeleton'
import theme from '@/theme'

const useStyles = makeStyles(() => ({
  body: {
    gap: '40px',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      gap: '8px',
    },
  },
  cartContainer: {
    width: '400px',
    [theme.breakpoints.down(1025)]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formContainer: {
    width: '100%',
    [theme.breakpoints.down(1025)]: {
      flex: 1,
    },
  },
}))

const FormAccountInfoContainer: FunctionComponent = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { query, push } = useRouter()
  const { details } = useSelector(selectPlanState)
  const invitationPlanState = useSelector(selectInvitationPlanState)
  const { account } = useSelector(selectEnrollmentState)
  const { details: activationDetails } = useSelector(selectActivationState)

  useTracker({ trigger: TrackingTriggerMapping.Account })

  useEffect(() => {
    if (query?.key && !invitationPlanState.isFetching && !invitationPlanState.details) {
      dispatch(getInvitationPlan({ key: query.key as string }))
    }
  }, [query, invitationPlanState])

  const handleSubmit = async function submit(fields: InitialValues) {
    dispatch(
      cacheAccountInfo({
        ...fields,
        activationCode: invitationPlanState.details?.couponCode || account.activationCode,
        membershipInvitationKey: query?.key,
        isValid: true,
        isPlanAuthRequired: details?.isPlanAuthRequired,
        trustedFormCertId: window.trustedForm?.id || '',
        isSaveOnly: true,
      }),
    )

    if (details?.isPlanAuthRequired) {
      await push({
        pathname: Routes.Identity,
        query,
      })
    } else if (!activationDetails) {
      dispatch(getQuickviewReport({}))
      await push({
        pathname: Routes.Purchase,
        query,
      })
    }

    dispatch(
      cacheAccountInfo({
        isValid: true,
        isPlanAuthRequired: details?.isPlanAuthRequired,
      }),
    )

    if (!activationDetails) {
      dispatch(
        addEnrollmentLead({
          email: fields.email,
          firstName: fields.firstName,
          lastName: fields.lastName,
          trustedFormCertId: window.trustedForm?.id || '',
          shipping: {
            currentCity: fields.currentCity,
            currentState: fields.currentState,
            currentStreet: fields.currentStreet,
            currentZip: fields.currentZip,
          },
        }),
      )
    }

    if (activationDetails && details && !details.isPlanAuthRequired) {
      dispatch(enrollMember())
    } else if (details?.isDisplayIdentityQuickView) {
      dispatch(getDarkWebData({ email: fields.email }))
    }
  }

  return (
    <Grid container className={classes.body}>
      <Grid item className={classes.formContainer}>
        {details ? <FormAccountInfo onSubmit={handleSubmit} /> : <AccountInfoSkeleton />}
      </Grid>
      <Grid item className={classes.cartContainer}>
        <CartContainer isAccountInfo={true} />
      </Grid>
    </Grid>
  )
}

export default FormAccountInfoContainer
