export const GA_TRACKING_ID_IDIQ = process.env.NEXT_PUBLIC_IDIQ_GAPROPERTYID ?? ''

interface Props {
  brand: string
  offerCode?: string
  totalValue: number
  plan?: string
  transactionId: string
  zip: string
  emailHashed: string
}

export const purchaseEvent = ({ brand, offerCode, totalValue, plan, transactionId, zip, emailHashed }: Props): void => {
  const Offer_Plan = offerCode + ' | ' + plan
  window.gtag('config', GA_TRACKING_ID_IDIQ, {
    custom_map: { dimension1: 'sku' },
  })
  window.gtag('event', 'purchase', {
    transaction_id: transactionId,
    affiliation: brand,
    sku: offerCode,
    value: totalValue,
    currency: 'USD',
    zip,
    emailHashed,
    items: [
      {
        name: Offer_Plan,
        brand: brand,
        category: offerCode,
        sku: offerCode,
        quantity: 1,
        price: totalValue,
      },
    ],
  })
}
