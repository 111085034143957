import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme, errorBorderColor } from '@/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  errorCard: {
    marginBottom: '12px',
    border: errorBorderColor,
    background: '#F9EEED url(./images/error.svg) no-repeat 9px 13px',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal',
    padding: '15px 34px 15px 42px',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
}))

interface Props {
  children: string
}

const ErrorCard: FunctionComponent<Props> = ({ children }: Props) => {
  const classes = useStyles()

  return <div id="errorCard" className={classes.errorCard} dangerouslySetInnerHTML={{ __html: children }}></div>
}

export default ErrorCard
