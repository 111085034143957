// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
let globalRecaptcha = {}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (typeof window !== 'undefined' && window.grecaptcha) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalRecaptcha = window.grecaptcha
}

const handler = {
  get(target: never, propKey: string) {
    const originalMethod = target[propKey]

    return function () {
      try {
        if (originalMethod) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return originalMethod.apply(this)
        } else {
          return
        }
      } catch (error) {
        return
      }
    }
  },
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const recaptchaUtil: Record<string, Function> = new Proxy(globalRecaptcha, handler)
