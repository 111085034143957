export const trimAllStringValues = (map: Record<string, string | void>): Record<string, string | void> => {
  return Object.keys(map).reduce((accumulator, key) => {
    if (typeof map[key] === 'string') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      accumulator[key] = map[key].trim()
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      accumulator[key] = map[key]
    }

    return accumulator
  }, {})
}
