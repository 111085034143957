export enum Routes {
  Enroll = '/',
  Identity = '/identity',
  Purchase = '/purchase',
  Verify = '/verify',
  TechError = '/techerror',
  AuthSuccess = '/authsuccess',
  SignIn = '/signIn',
  Unauthorized = '/unauthorized',
  EffortError = '/enroll-error',
}
