import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { GetQuickviewReportResponse } from '@idiq-ppd/idiq-client/src/IDIQParser/types/GetQuickviewResponseData'
import { getQuickviewReport } from './actions/getQuickviewReport'
import { SessionStore } from '@/constants'

export interface QuickviewState {
  isFetching: boolean
  error: null | string
  quickviewReport?: GetQuickviewReportResponse
  disableQuickview: boolean
  hideQuickview?: boolean
}

const initialState: QuickviewState = {
  isFetching: false,
  error: null,
  disableQuickview: false,
  hideQuickview: true,
}

const quickview: Slice = createSlice({
  name: 'quickview',
  initialState,
  reducers: {
    cacheQuickviewReport: (
      state,
      action: PayloadAction<{ quickviewReport: GetQuickviewReportResponse; disableQuickview: boolean }>,
    ) => {
      state.quickviewReport = action.payload.quickviewReport
      state.disableQuickview = action.payload.disableQuickview
      state.error = null
      state.isFetching = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuickviewReport.pending, (state) => {
        state.isFetching = true
      })
      .addCase(getQuickviewReport.rejected, (state, action) => {
        state.isFetching = false
        state.error = action.payload as string
      })
      .addCase(getQuickviewReport.fulfilled, (state, action) => {
        state.isFetching = false
        state.quickviewReport = action.payload.quickview
        state.disableQuickview = action.payload.disableQuickview
        state.hideQuickview = action.payload.hideQuickview

        sessionStorage?.setItem(SessionStore.DisableQuickview, JSON.stringify(state.disableQuickview))
        if (action.payload.quickview) {
          sessionStorage?.setItem(SessionStore.QuickviewReport, JSON.stringify(state.quickviewReport))
        }
      })
  },
})

export const { cacheQuickviewReport } = quickview.actions
export default quickview.reducer
