import React, { FunctionComponent, ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
  on: string | number
}

const Switch: FunctionComponent<Props> = ({ children, on }: Props) => {
  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as ReactElement, {
          active: on,
        })
      })}
    </>
  )
}

export default Switch

interface CaseProps {
  id: string | number
  component: ReactElement
  active?: string
}

export const Case: FunctionComponent<CaseProps> = ({ component, active, id }: CaseProps) => {
  return id === active ? component : null
}
