import React, { FunctionComponent, SyntheticEvent } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { copyFontSize, Theme } from '@/theme'
import Button from '@material-ui/core/Button'
import { Grid } from '@material-ui/core'
import AnimatedPlayButton from '../AnimatedPlayButton'
import { useRouter } from 'next/router'
import { ErrorCodes, Routes, TUErrorResponseCodes } from '@/constants'
import { submitButtonBoxShadow } from '@/theme'
import { useSelector } from 'react-redux'
import { selectEnrollmentState, selectPlanState } from '@/state/selectors'
import { EnrollmentStep } from '@/state/types'
import { isNoAccount } from '@/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      height: '60px',
      margin: '3px 31px 0 31px',
      width: '338px',
      maxWidth: '100%',
      boxShadow: submitButtonBoxShadow,
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        margin: '3px 0 0 0',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '24px',
      },
    },
    submitButtonIcon: {
      position: 'absolute',
      right: '0',
      marginRight: '-10px',
      maxWidth: '80px',
      marginTop: '0px',
      display: 'flex',
    },
    submitButtonWrap: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
    },
    submitText: {
      fontSize: copyFontSize,
      fontWeight: 'bold',
      letterSpacing: '2px',
      color: theme.palette.common.white,
      textTransform: 'uppercase',
    },
    validDesc: {
      marginBottom: '0',
      color: theme.palette.common.black,
    },
    smallVariant: {
      '& .MuiGrid-root': {
        alignItems: 'flex-start',
      },
      '& .MuiButton-root': {
        width: 'auto',
        height: '40px',
        borderRadius: '20px',
        padding: '7px 30px',
        boxShadow: 'none',
        margin: '20px 0 0 0',
      },
      '& p': {
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: 'normal',
        textTransform: 'none',
      },
    },
  }),
)

interface Props {
  form?: string
  onClick?: () => void
  disabled?: boolean
  text?: string
  hideIcon?: boolean
  isSmall?: boolean
}

const SubmitButton: FunctionComponent<Props> = ({ form, onClick, disabled, text, hideIcon, isSmall }: Props) => {
  const classes = useStyles()
  const router = useRouter()
  const { errorCode, error, step } = useSelector(selectEnrollmentState)
  const { details } = useSelector(selectPlanState)
  const id = router.pathname === Routes.Enroll ? 'account-next-btn' : `${router.pathname.substring(1)}-next-btn`
  const isLastStep =
    (isNoAccount(details) && step === EnrollmentStep.IdentityInfo) || step === EnrollmentStep.BillingInfo
  text = text || (isLastStep && 'Submit') || 'Next'

  disabled =
    disabled ||
    errorCode === ErrorCodes.DuplicateMemberBySSN ||
    errorCode === ErrorCodes.DuplicateMemberByHousehold ||
    TUErrorResponseCodes.has(errorCode || '') ||
    error?.toLocaleLowerCase().trim() === 'duplicate member by account'

  return (
    <Grid container className={isSmall ? classes.smallVariant : ''}>
      <Grid item xs={12} className={classes.submitButtonWrap}>
        <Button
          type="submit"
          variant="contained"
          form={form}
          id={id}
          className={classes.submitButton}
          disabled={disabled}
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation()
            onClick && onClick()
          }}
        >
          <p className={classes.submitText}>{text}</p>
          {!hideIcon && <AnimatedPlayButton className={classes.submitButtonIcon}></AnimatedPlayButton>}
        </Button>
      </Grid>
    </Grid>
  )
}

export default SubmitButton
