import React, { FunctionComponent, ReactNode } from 'react'
import Typography from '@material-ui/core/Typography'

interface Props {
  children: ReactNode
  className?: string
}

export const H1: FunctionComponent<Props> = ({ children, className }: Props) => {
  return (
    <Typography variant="h1" color="textSecondary" className={className}>
      {children}
    </Typography>
  )
}

export const H2: FunctionComponent<Props> = ({ children, className }: Props) => {
  return (
    <Typography variant="h2" color="textSecondary" className={className}>
      {children}
    </Typography>
  )
}
