import { Theme } from '@material-ui/core'
import IdentityIQ from './identityIQTheme'
import MyScoreIQ from './myScoreIQTheme'
import CreditScoreIQ from './creditScoreIQTheme'
import CreditBuilderIQ from './creditBuilderIQTheme'

const themeMap: { [key: string]: Theme } = {
  IdentityIQ,
  MyScoreIQ,
  CreditScoreIQ,
  CreditBuilderIQ,
}

export function getTheme(theme: string): Theme {
  return themeMap[theme]
}
