import React, { FunctionComponent } from 'react'
import Header from '@/components/Header'
import { useSelector } from 'react-redux'
import { selectPlanState } from '@/state/selectors'

const HeaderContainer: FunctionComponent = () => {
  const { details } = useSelector(selectPlanState)

  return <Header businessLogo={details?.businessLogo} pencilBannerEnabled={details?.pencilBannerEnabled} />
}

export default HeaderContainer
